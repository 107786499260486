/*------------------------------------------------------------------
[Table of contents]

1. Reset
2. Dashboard 1
	2.1. Top Widgets
	2.2. Breadcrumbs
	2.3. Info Boxes
	2.4  Distance Graph
	2.5. Team Members
	2.6. Task Widget
	2.7. Client Widget
        2.8. Weather Widget
	2.9. Visitor Widget
	2.10. Alert Widget
	2.11. Map Widget
	2.12. Announcement Widget
	2.13. Event Widget

3. Dashboard 2
	3.1. Trending Widget
	3.2. Leads Table
	3.3. Google Map
4. Mail Box 1
5. Mail Box 2
6. Alert Page
7. Button Page
8. Animation Page
9. Breadcrumbs Page
10. Icon Page
11. Modal Page
12. Navbar Page
13. Pagination Page
14. Panels Page
15. Progress Page
16. Tabs Page
17. Tree View Page
18. Typography
19. Widgets
20. Calendar
21. Ecommerce
22. Products
23. Notification
24. User Profile
25. Pricing
26. Lock Screen
27. About Page
28. Contact
29. Blog Page
30. Blog Post Page
31. FAQ Page
32. Invoice Page
33. Gallery Page
34. Coming Soon
35. Search
36. Basic Forms
37. Advance Forms
38. Chart Js
39. Data Tables
40. Google Maps
41. Documentation

-------------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700");
/* line 5, ../../../../../../Ruby23-x64/lib/ruby/gems/2.3.0/gems/compass-core-1.1.0.alpha.3/stylesheets/compass/reset/_utilities.scss */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

/* line 22, ../../../../../../Ruby23-x64/lib/ruby/gems/2.3.0/gems/compass-core-1.1.0.alpha.3/stylesheets/compass/reset/_utilities.scss */
html {
  line-height: 1;
}

/* line 24, ../../../../../../Ruby23-x64/lib/ruby/gems/2.3.0/gems/compass-core-1.1.0.alpha.3/stylesheets/compass/reset/_utilities.scss */
ol, ul {
  list-style: none;
}

/* line 26, ../../../../../../Ruby23-x64/lib/ruby/gems/2.3.0/gems/compass-core-1.1.0.alpha.3/stylesheets/compass/reset/_utilities.scss */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* line 28, ../../../../../../Ruby23-x64/lib/ruby/gems/2.3.0/gems/compass-core-1.1.0.alpha.3/stylesheets/compass/reset/_utilities.scss */
caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

/* line 30, ../../../../../../Ruby23-x64/lib/ruby/gems/2.3.0/gems/compass-core-1.1.0.alpha.3/stylesheets/compass/reset/_utilities.scss */
q, blockquote {
  quotes: none;
}
/* line 103, ../../../../../../Ruby23-x64/lib/ruby/gems/2.3.0/gems/compass-core-1.1.0.alpha.3/stylesheets/compass/reset/_utilities.scss */
q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

/* line 32, ../../../../../../Ruby23-x64/lib/ruby/gems/2.3.0/gems/compass-core-1.1.0.alpha.3/stylesheets/compass/reset/_utilities.scss */
a img {
  border: none;
}

/* line 116, ../../../../../../Ruby23-x64/lib/ruby/gems/2.3.0/gems/compass-core-1.1.0.alpha.3/stylesheets/compass/reset/_utilities.scss */
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

/*color variables*/
/* line 82, ../sass/app.scss */
p {
  font-size: 16px;
  line-height: 18px;
  margin: 0 0 10px;
}

/* line 88, ../sass/app.scss */
a {
  outline: none;
}

/* line 92, ../sass/app.scss */
blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee;
}

/* line 98, ../sass/app.scss */
ol, ul {
  margin-bottom: 10px;
  font-size: 14px;
}

/* line 103, ../sass/app.scss */
.list-unstyled {
  margin: 0px;
}

/* line 106, ../sass/app.scss */
ul {
  list-style: disc;
  padding-left: 30px;
}
/* line 109, ../sass/app.scss */
ul li {
  line-height: 22px;
}
/* line 111, ../sass/app.scss */
ul li ul {
  list-style: circle;
}

/* line 116, ../sass/app.scss */
ol {
  padding-left: 30px;
  list-style: decimal;
}
/* line 119, ../sass/app.scss */
ol li {
  line-height: 22px;
}
/* line 121, ../sass/app.scss */
ol li ol {
  list-style: decimal;
}

/* line 127, ../sass/app.scss */
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  letter-spacing: 1px;
}

/* line 131, ../sass/app.scss */
.h1, .h2, h1, h2 {
  margin-top: 20px;
  margin-bottom: 10px;
}

/* line 136, ../sass/app.scss */
h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* line 140, ../sass/app.scss */
h1, .h1 {
  font-size: 36px;
}

/* line 143, ../sass/app.scss */
h2, .h2 {
  font-size: 30px;
}

/* line 146, ../sass/app.scss */
h3, .h3 {
  font-size: 24px;
}

/* line 149, ../sass/app.scss */
h4, .h4 {
  font-size: 18px;
}

/* line 152, ../sass/app.scss */
h5, .h5 {
  font-size: 14px;
}

/* line 155, ../sass/app.scss */
h6, .h6 {
  font-size: 12px;
}

/* line 158, ../sass/app.scss */
.panel-heading {
  padding: 15px;
  font-size: 15px;
  font-weight: 600;
}

/* line 163, ../sass/app.scss */
.noPadding {
  padding: 0px;
}

/* line 166, ../sass/app.scss */
.noMargin {
  margin: 0px !important;
}

/* line 169, ../sass/app.scss */
.orangeBG {
  background-color: #ff9501;
}

/* line 172, ../sass/app.scss */
.orangeColor {
  color: #ff9501;
}

/* line 175, ../sass/app.scss */
.redBG {
  background-color: #e74c3c;
}

/* line 178, ../sass/app.scss */
.redColor {
  color: #e74c3c;
}

/* line 181, ../sass/app.scss */
.greenBG {
  background-color: #76c80e;
}

/* line 184, ../sass/app.scss */
.greenColor {
  color: #76c80e;
}

/* line 187, ../sass/app.scss */
.blueBG {
  background-color: #1b93e1;
}

/* line 190, ../sass/app.scss */
.blueColor {
  color: #1b93e1;
}

.lightGrey {
  color: #999;
}

/* line 193, ../sass/app.scss */
body {
  font-family: 'Open Sans', sans-serif !important;
  overflow-x: hidden !important;
  background-color: #F0F3F3;
  position: relative;
}

#main-wrapper {
  min-height: 900px;
}

/* line 197, ../sass/app.scss */
.main-sidebar {
  background: #333333;
  width: 270px;
  position: fixed;
  left: 0px;
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 50px;
  z-index: 100;
  color: #FFF;
}
/* line 208, ../sass/app.scss */
.main-sidebar .logo {
  background: #1b93e1;
  padding: 5px 0px;
  margin-bottom: 10px;
  width: 100%;
}
/* line 213, ../sass/app.scss */
.main-sidebar .logo a {
  display: inline-block;
}
/* line 215, ../sass/app.scss */
.main-sidebar .logo a img {
  max-height: 55px;
}
/* line 221, ../sass/app.scss */
.main-sidebar nav {
  width: 100%;
}
/* line 223, ../sass/app.scss */
.main-sidebar nav .title-txt {
  padding: 52px 0px;
  font-size: 36px;
  font-weight: 300;
}
/* line 227, ../sass/app.scss */
.main-sidebar nav .title-txt strong {
  font-weight: 600;
}
/* line 233, ../sass/app.scss */
.main-sidebar nav .sidebar-menu li a {
  background-color: transparent;
  display: block;
  padding: 13px 20px 13px 30px;
  color: #FFF;
  margin-bottom: 1px;
  font-size: 15px;
  text-decoration: none;
  outline: none;
  -moz-transition-duration: 0.6s;
  -o-transition-duration: 0.6s;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}
/* line 243, ../sass/app.scss */
.main-sidebar nav .sidebar-menu li a .icon {
  margin-right: 14px;
}
/* line 246, ../sass/app.scss */
.main-sidebar nav .sidebar-menu li a .label-number {
  padding: 0px 5px;
  text-align: center;
  font-size: 12px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
/* line 253, ../sass/app.scss */
.main-sidebar nav .sidebar-menu li a .arrow {
  float: right;
  font-size: 10px;
  margin-top: 2px;
}
/* line 259, ../sass/app.scss */
.main-sidebar nav .sidebar-menu li a:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
/* line 264, ../sass/app.scss */
.main-sidebar nav .sidebar-menu li .active {
  background-color: rgba(0, 0, 0, 0.5);
}
/* line 267, ../sass/app.scss */
.main-sidebar nav .sidebar-menu li .active .fa-chevron-down:before {
  content: "\f077";
}
/* line 277, ../sass/app.scss */
.main-sidebar nav .sidebar-menu.submenu {
  background-color: rgba(0, 0, 0, 0.2);
}
/* line 281, ../sass/app.scss */
.main-sidebar nav .sidebar-menu.submenu li a {
  padding: 12px 20px 12px 40px;
  font-size: 15px;
}
/* line 284, ../sass/app.scss */
.main-sidebar nav .sidebar-menu.submenu li a .icon {
  font-size: 12px;
}
/* line 287, ../sass/app.scss */
.main-sidebar nav .sidebar-menu.submenu li a.active {
  background-color: #404040;
  color: #1b93e1;
}
/* line 295, ../sass/app.scss */
.main-sidebar nav .sidebar-menu.submenu li ul li a {
  padding: 13px 20px 13px 55px;
}
/* line 300, ../sass/app.scss */
.main-sidebar nav .sidebar-menu.submenu li ul li ul li a {
  padding: 13px 20px 13px 65px;
}
/* line 309, ../sass/app.scss */
.main-sidebar nav .nav-header {
  padding: 15px 20px;
  background-color: #1b93e1;
  font-size: 15px;
  font-weight: 600;
  line-height: 8px;
}

/* Handle */
/* line 323, ../sass/app.scss */
.main-sidebar::-webkit-scrollbar-thumb {
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #333333;
  -webkit-box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.8);
}

/* line 328, ../sass/app.scss */
.main-sidebar::-moz-scrollbar-thumb {
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #1b93e1;
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.8);
}

/* line 333, ../sass/app.scss */
.menu-toggle {
  color: #FFF;
  display: inline-block;
  padding: 9px 10px 0px 10px;
  margin-top: 1px;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
/* line 339, ../sass/app.scss */
.menu-toggle i {
  width: 28px;
  height: 1px;
  display: block;
  margin-bottom: 9px;
  background-color: #FFF;
}
/* line 346, ../sass/app.scss */
.menu-toggle:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

/* line 350, ../sass/app.scss */
.home-chart {
  height: 300px !important;
  padding-bottom: 10px;
}

/* line 360, ../sass/app.scss */
.main-content {
  padding: 0px 15px 15px 15px;
  background-color: #edf0f5;
}

/* line 360, ../sass/app.scss */
.main-content1 {
  /* margin-left: 270px; */
  padding: 0px 15px 15px 15px;
  background-color: #edf0f5;
}

/*top widgets*/
/* line 366, ../sass/app.scss */
.top-widgets {
  margin-top: 5px;
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.top-widgets1 {
  margin-top: 5px;
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
/* line 376, ../sass/app.scss */
.top-widgets .action-icons ul li {
  float: left;
  padding: 0px 20px;
  display: inline-block;
}
/* line 382, ../sass/app.scss */
.top-widgets .action-icons ul li a i {
  font-size: 32px;
  position: relative;
  color: #FFF;
}
/* line 386, ../sass/app.scss */
.top-widgets .action-icons ul li a i span {
  width: 17px;
  height: 17px;
  font-family: 'Open Sans', sans-serif;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  display: block;
  position: absolute;
  color: #FFF;
  top: -11px;
  left: 8px;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
}

/* line 408, ../sass/app.scss */
.action-dropdown {
  top: 100%;
  padding: 0px;
  border: 1px solid #ccc;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
/* line 414, ../sass/app.scss */
.action-dropdown .drop-header span {
  padding: 15px 10px;
  font-size: 14px;
  font-weight: 600;
  color: #FFF;
  display: block;
  text-transform: uppercase;
}
/* line 423, ../sass/app.scss */
.action-dropdown li {
  width: 100%;
  padding: 0px !important;
}
/* line 426, ../sass/app.scss */
.action-dropdown li a {
  color: #333;
  padding: 15px 10px;
  display: block;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
/* line 432, ../sass/app.scss */
.action-dropdown li a:hover {
  background-color: #eeeeee;
}

/* line 439, ../sass/app.scss */
.unread {
  background-color: #f3f3f3;
}

/* line 442, ../sass/app.scss */
.user-setting-drop {
  width: 100%;
}
/* line 445, ../sass/app.scss */
.user-setting-drop li a {
  font-weight: 600;
  padding: 12px 15px;
}
/* line 448, ../sass/app.scss */
.user-setting-drop li a i {
  display: inline-block;
  margin-right: 12px;
}

/* line 458, ../sass/app.scss */
.email-drop li a img {
  float: left;
  margin-right: 10px;
}
/* line 462, ../sass/app.scss */
.email-drop li a .email-cont {
  padding-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* line 468, ../sass/app.scss */
.email-drop li a strong {
  font-weight: 700;
}

/* line 474, ../sass/app.scss */
.time-stat {
  display: block;
  font-size: 13px;
  color: #bcbcbc;
}

/* line 479, ../sass/app.scss */
.dropdown-backdrop {
  display: none;
}

/*Search widget*/
/* line 484, ../sass/app.scss */
.search-top-widget {
  width: 100%;
}
/* line 486, ../sass/app.scss */
.search-top-widget form {
  position: relative;
  padding: 0px 15px;
}
/* line 490, ../sass/app.scss */
.search-top-widget input {
  border: transparent solid 1px;
  background-color: #FFF;
  padding: 6px;
  border: none;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  outline: none !important;
}
/* line 497, ../sass/app.scss */
.search-top-widget input:focus {
  box-shadow: none;
}
/* line 501, ../sass/app.scss */
.search-top-widget .input-group-addon {
  border: none;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  background-color: #1b93e1;
  color: #FFF;
}
/* line 508, ../sass/app.scss */
.search-top-widget .input-group-addon:hover {
  background-color: #00a33d;
}
/* line 513, ../sass/app.scss */
.search-top-widget .search-btn {
  background-color: transparent;
  border: none;
  position: absolute;
  outline: none;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  padding: 0px;
  left: 90%;
  top: 6px;
  z-index: 100;
}
/* line 523, ../sass/app.scss */
.search-top-widget .search-btn i {
  color: #333;
  font-size: 18px;
}

/*Profile Widget*/
/* line 538, ../sass/app.scss */
.profile-widget a {
  display: table;
  color: #FFF;
  text-decoration: none;
  outline: none;
}
/* line 543, ../sass/app.scss */
.profile-widget a i {
  display: table-cell;
  vertical-align: middle;
}
/* line 548, ../sass/app.scss */
.profile-widget a .user-pic {
  font-size: 25px;
  border: #FFF solid 2px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  padding: 5px 8px;
}
/* line 555, ../sass/app.scss */
.profile-widget a .user-name {
  font-weight: 300;
  display: table-cell;
  font-size: 20px;
  padding: 0px 10px;
  vertical-align: middle;
}
/* line 562, ../sass/app.scss */
.profile-widget a .user-setting {
  font-size: 32px;
}

/*breadcrumb*/
/* line 572, ../sass/app.scss */
.page-links .breadcrumb {
  background-color: #e6e6e6;
  padding: 10px 20px;
  margin-bottom: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
/* line 577, ../sass/app.scss */
.page-links .breadcrumb i {
  padding: 0px 6px;
}
/* line 581, ../sass/app.scss */
.page-links .breadcrumb .breadcrumb-item a {
  color: #000;
  font-weight: 300;
  font-size: 14px;
}
/* line 586, ../sass/app.scss */
.page-links .breadcrumb .breadcrumb-item:before {
  content: "" !important;
}
/* line 590, ../sass/app.scss */
.page-links .breadcrumb .breadcrumb-item.active {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

/*info boxes*/
/* line 601, ../sass/app.scss */
.info-box {
  color: #FFF;
  position: relative;
  margin: 30px 0px;
  padding: 30px 20px 10px 20px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
/* line 607, ../sass/app.scss */
.info-box .info-box-head {
  position: absolute;
  width: 80px;
  height: 80px;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  top: -55px;
  left: 33%;
}
/* line 618, ../sass/app.scss */
.info-box .info-box-head i {
  font-size: 38px;
}
/* line 623, ../sass/app.scss */
.info-box .clients .info-box-head {
  border: #e74c3c solid 2px;
}
/* line 628, ../sass/app.scss */
.info-box .projects .info-box-head {
  border: #76c80e solid 2px;
}
/* line 633, ../sass/app.scss */
.info-box .oldprojects .info-box-head {
  border: #ff9501 solid 2px;
}
/* line 638, ../sass/app.scss */
.info-box .employee .info-box-head {
  border: #1b93e1 solid 2px;
}
/* line 646, ../sass/app.scss */
.info-box .box-title {
  font-weight: 300;
  font-size: 18px;
}
/* line 649, ../sass/app.scss */
.info-box .box-title strong {
  font-weight: 600;
}
/* line 653, ../sass/app.scss */
.info-box .total-number {
  padding: 20px 0px;
}
/* line 656, ../sass/app.scss */
.info-box .info-number {
  font-size: 22px;
  font-weight: 300;
  margin-top: 4px;
}
/* line 662, ../sass/app.scss */
.info-box .info-action span {
  font-size: 16px;
  font-weight: 300;
}
/* line 666, ../sass/app.scss */
.info-box .info-action a {
  display: inline-block;
  width: 22px;
  height: 22px;
  border: #FFF solid 2px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  color: #FFF;
  text-align: center;
  font-size: 12px;
  padding: 4px 0px 0px 2px;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
/* line 678, ../sass/app.scss */
.info-box .info-action a:hover {
  background-color: #FFF;
  color: #000;
}

/*distance graph*/
/* line 688, ../sass/app.scss */
.distance-graph {
  background: #1fbba6;
}
/* line 690, ../sass/app.scss */
.distance-graph .graph-title {
  padding: 10px;
}
/* line 692, ../sass/app.scss */
.distance-graph .graph-title span {
  color: #FFF;
  font-size: 18px;
  font-weight: 400;
  padding-top: 4px;
}
/* line 699, ../sass/app.scss */
.distance-graph .graph-title ul li {
  position: relative;
  display: inline-block;
  float: left;
  padding: 0px 3px;
}
/* line 704, ../sass/app.scss */
.distance-graph .graph-title ul li a {
  color: #FFF;
  text-decoration: none;
  padding: 2px 10px;
  display: inline-block;
  font-size: 12px;
  border: #FFF solid 1px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
/* line 714, ../sass/app.scss */
.distance-graph .graph-title ul li a:hover {
  background-color: #FFF;
  color: #000;
}
/* line 718, ../sass/app.scss */
.distance-graph .graph-title ul li a:focus {
  background-color: #FFF;
  color: #000;
}
/* line 723, ../sass/app.scss */
.distance-graph .graph-title ul li ul {
  margin-top: 2px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  padding: 0px;
  border: none !important;
}
/* line 729, ../sass/app.scss */
.distance-graph .graph-title ul li ul li {
  border: none !important;
  display: block !important;
  width: 100%;
  padding: 0px;
}
/* line 736, ../sass/app.scss */
.distance-graph .graph-title ul li ul li a {
  color: #000;
  padding: 6px 10px;
  display: block !important;
  font-size: 15px;
  border-bottom: #ccc solid 1px;
  font-weight: 400;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
/* line 744, ../sass/app.scss */
.distance-graph .graph-title ul li ul li a:hover {
  background: #f0f0f0;
}

/* line 757, ../sass/app.scss */
#chartdiv {
  width: 100%;
  height: 300px;
}

/* line 761, ../sass/app.scss */
.amChartsLegend {
  display: none;
}

/*Team members*/
/* line 767, ../sass/app.scss */
.team-box-title {
  padding: 18px;
  color: #FFF;
  font-weight: 600;
  font-size: 16px;
}

/* line 773, ../sass/app.scss */
.member-list {
  border-top: #e3e3e3 solid 1px;
  border-left: #e3e3e3 solid 1px;
  border-right: #e3e3e3 solid 1px;
  overflow: hidden;
}
/* line 777, ../sass/app.scss */
.member-list ul {
  padding: 0px;
}
/* line 780, ../sass/app.scss */
.member-list ul li a {
  display: block;
  padding: 9px 10px;
  color: #000;
  text-decoration: none;
  outline: none;

}
/* line 787, ../sass/app.scss */
.member-list ul li a .user-info {
  width: 90%;
}
.member-list ul li {
  padding: 10px 2px;
  border-bottom: #e7e7e7 solid 1px;
}
/* line 795, ../sass/app.scss */
.member-list ul li a .member-name {
  font-size: 14px;
  display: inline-block;
  margin-top: 5px;
}
/* line 799, ../sass/app.scss */
.member-list ul li a .member-name .member-online-time {
  color: #c1c1c1;
  font-size: 12px;
}
/* line 804, ../sass/app.scss */
.member-list ul li a .message-status {
  width: 30%;
}
/* line 807, ../sass/app.scss */
.member-list ul li a .member-message {
  display: inline-block;
  vertical-align: middle;
  padding: 10px 10px;
}
/* line 813, ../sass/app.scss */
.member-list ul li a .member-status {
  display: inline-block;
}
/* line 816, ../sass/app.scss */
.member-list ul li a .member-status i {
  display: block;
  width: 10px;
  height: 10px;
  margin-top: -10px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
}
/* line 823, ../sass/app.scss */
.member-list ul li a .member-status .online {
  background-color: #76c80e;
}
/* line 826, ../sass/app.scss */
.member-list ul li a .member-status .offline {
  background-color: #faf44a;
}

/*Tasks Widget*/
/* line 839, ../sass/app.scss */
.tasks-widget {
  margin: 30px 0px;
  box-shadow: 0px 0px 10px #CCC;
}
/* line 842, ../sass/app.scss */
.tasks-widget .widget-head {
  background-color: #1fbba6;
}

/* line 847, ../sass/app.scss */
.widget-head {
  color: #FFF;
  height: 52px;
  padding: 10px 15px;
  position: relative;
}
/* line 853, ../sass/app.scss */
.widget-head h3 {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0px;
}
/* line 860, ../sass/app.scss */
.widget-head span {
  font-size: 12px;
}
/* line 863, ../sass/app.scss */
.widget-head a {
  position: absolute;
  width: 32px;
  height: 32px;
  display: block;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  -moz-transition-duration: 1.5s;
  -o-transition-duration: 1.5s;
  -webkit-transition-duration: 1.5s;
  transition-duration: 1.5s;
  text-align: center;
  line-height: 31px;
  right: 15px;
  bottom: -6px;
  color: #FFF;
}
/* line 875, ../sass/app.scss */
.widget-head a:hover {
  opacity: 0.9;
}
/* line 878, ../sass/app.scss */
.widget-head a:focus {
  outline: none;
}

/* line 887, ../sass/app.scss */
.widget-content ul li {
  padding: 8px 15px;
  border-bottom: #e7e7e7 solid 1px;
}
/* line 892, ../sass/app.scss */
.widget-content ul .task-name {
  display: table;
}
/* line 894, ../sass/app.scss */
.widget-content ul .task-name input {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  display: table-cell;
  vertical-align: top;
  margin-top: 4px;
}
/* line 902, ../sass/app.scss */
.widget-content ul .task-name .task-values {
  display: table-cell;
  width: 100%;
}
/* line 905, ../sass/app.scss */
.widget-content ul .task-name .task-values label {
  font-size: 12px;
  color: #000;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}
/* line 912, ../sass/app.scss */
.widget-content ul .task-name .task-values .task-type {
  margin-top: 10px;
  display: block;
}
/* line 915, ../sass/app.scss */
.widget-content ul .task-name .task-values .task-type .label-type {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  color: #FFF;
  text-align: center;
  padding: 1px 3px;
  font-size: 10px;
  line-height: 16px;
}
/* line 924, ../sass/app.scss */
.widget-content ul .task-name .task-values .task-type .label-day {
  font-size: 12px;
  font-weight: 600;
}

/*client widget*/
/* line 938, ../sass/app.scss */
.clients-widget {
  margin: 30px 0px;
  box-shadow: 0px 0px 10px #CCC;
}
/* line 943, ../sass/app.scss */
.clients-widget .widget-content ul li {
  padding: 11px 15px;
}
/* line 948, ../sass/app.scss */
.clients-widget .client-name {
  display: inline-block;
}
/* line 950, ../sass/app.scss */
.clients-widget .client-name p {
  font-size: 14px;
  color: #000;
}
/* line 954, ../sass/app.scss */
.clients-widget .client-name .client-loc {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 600;
}
/* line 961, ../sass/app.scss */
.clients-widget .find-more a {
  display: inline-block;
  padding: 10px 0px;
}
/* line 1022, ../sass/app.scss */
.aw-widget-content {
  min-height: 300px !important;
}

/* line 1025, ../sass/app.scss */
.calendar-widget {
  margin: 30px 0px;
  box-shadow: 0px 0px 10px #CCC;
}
/* line 1028, ../sass/app.scss */
.calendar-widget #adminCalendar {
  width: 100% !important;
  border-radius: 0px;
  border: none;
  height: 290px !important;
  padding-bottom: 10px;
}

/* line 1036, ../sass/app.scss */
.dash1-calendar {
  padding-bottom: 40px;
  background-color: #FFF;
}

/*Admin widgets*/
/* line 1042, ../sass/app.scss */
.admin-widgets {
  border: #e7e7e7 solid 1px;
  border-bottom: none;
}
/* line 1045, ../sass/app.scss */
.admin-widgets .admin-head {
  padding: 10px 10px;
  display: block;
  border-bottom: #e7e7e7 solid 1px;
}
/* line 1052, ../sass/app.scss */
.admin-widgets .admin-head .name-ico {
  width: 24px;
  height: 24px;
  line-height: 24px;
  color: #FFF;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  text-align: center;
  display: inline-block;
}
/* line 1061, ../sass/app.scss */
.admin-widgets .admin-head span {
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
}
/* line 1067, ../sass/app.scss */
.admin-widgets .admin-head .pull-right a {
  display: block;
  color: #FFF;
  padding: 4px 6px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
}
/* line 1075, ../sass/app.scss */
.admin-widgets .detail-list {
  display: block;
  border-bottom: #e7e7e7 solid 1px;
}
/* line 1079, ../sass/app.scss */
.admin-widgets .detail-list ul {
  padding: 10px 0px;
  text-align: center;
  display: table;
  width: 100%;
  border-bottom: #e7e7e7 solid 1px;
}
/* line 1085, ../sass/app.scss */
.admin-widgets .detail-list ul li {
  display: table-cell;
  vertical-align: middle;
  padding: 0px 15px;
  text-align: left;
}
/* line 1092, ../sass/app.scss */
.admin-widgets .detail-list ul .pro-name {
  font-size: 13px;
  width: 85%;
}
/* line 1094, ../sass/app.scss */
.admin-widgets .detail-list ul .pro-name strong {
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 5px;
}
/* line 1101, ../sass/app.scss */
.admin-widgets .detail-list ul .issue-status {
  width: 70px;
}
/* line 1103, ../sass/app.scss */
.admin-widgets .detail-list ul .issue-status .progress {
  height: 9px;
  margin-bottom: 0px;
  background-color: transparent;
  border: #7f7f7f solid 1px;
}

/* line 1117, ../sass/app.scss */
.issues-list ul {
  padding: 15px 0px !important;
}

/* line 1121, ../sass/app.scss */
.admin-label {
  vertical-align: middle;
}
/* line 1123, ../sass/app.scss */
.admin-label i {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  color: #FFF;
  padding: 3px 10px;
  font-size: 11px;
}

/*weather widget*/
/* line 1134, ../sass/app.scss */
div.aw-widget-current-inner a.aw-toggle {
  display: none !important;
}

/*visitor widget*/
/* line 1140, ../sass/app.scss */
.daily-visitor {
  margin: 30px 0px;
  background-color: #fafafa;
  overflow: hidden;
}
/* line 1144, ../sass/app.scss */
.daily-visitor .graph-head {
  padding: 15px 10px;
}
/* line 1146, ../sass/app.scss */
.daily-visitor .graph-head h3 {
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0px;
}

/* line 1155, ../sass/app.scss */
#graph {
  width: 100%;
  height: 327px;
}

/*Alert widget*/
/* line 1163, ../sass/app.scss */
.alert-widget, .home-event-widget {
  margin: 30px 0px;
  border: #e0e0e0 solid 1px;
  border-bottom: 0px;
}
/* line 1167, ../sass/app.scss */
.alert-widget .alert-list, .home-event-widget .alert-list {
  overflow: hidden;
  border-bottom: #e0e0e0 solid 1px;
}
/* line 1171, ../sass/app.scss */
.alert-widget .alert-list ul li, .home-event-widget .alert-list ul li {
  border-bottom: #e0e0e0 solid 1px;
  padding: 8px 15px;
  display: table;
  width: 100%;
}
/* line 1176, ../sass/app.scss */
.alert-widget .alert-list ul li i, .home-event-widget .alert-list ul li i {
  display: table-cell;
  vertical-align: middle;
  font-size: 22px;
  margin-right: 15px;
}
/* line 1182, ../sass/app.scss */
.alert-widget .alert-list ul li span, .home-event-widget .alert-list ul li span {
  display: table-cell;
  vertical-align: middle;
  font-size: 14px;
  color: #727272;
}
/* line 1187, ../sass/app.scss */
.alert-widget .alert-list ul li span strong, .home-event-widget .alert-list ul li span strong {
  font-weight: 600;
  display: inline-block;
  color: #000;
}

/* line 1200, ../sass/app.scss */
.home-event-widget .widget-head {
  height: auto;
}
/* line 1202, ../sass/app.scss */
.home-event-widget .widget-head h3 {
  padding: 5px 0px;
}
/* line 1212, ../sass/app.scss */
.home-event-widget .alert-list ul li i {
  font-weight: 600;
  font-size: 16px;
  color: #333;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  text-align: center;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
/* line 1223, ../sass/app.scss */
.home-event-widget .alert-list ul li span {
  padding-left: 10px;
}
/* line 1225, ../sass/app.scss */
.home-event-widget .alert-list ul li span span {
  padding-left: 0px;
  color: #aaa;
  font-size: 12px;
}
/* line 1231, ../sass/app.scss */
.home-event-widget .alert-list ul li span strong a {
  color: #1b93e1;
  text-decoration: none;
  outline: none;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
/* line 1236, ../sass/app.scss */
.home-event-widget .alert-list ul li span strong a:hover {
  color: #666;
}


/*announcements widget*/
/* line 1324, ../sass/app.scss */
.announcements {
  margin: 20px 0px;
  box-shadow: 0 0 5px #CCC;
}
/* line 1327, ../sass/app.scss */
.announcements .widget-head {
  height: auto;
}
/* line 1335, ../sass/app.scss */
.announcements .announcements-list ul li {
  border-bottom: #e6e6e6 solid 1px;
  width: 100%;
  padding: 8px 10px;
}
/* line 1339, ../sass/app.scss */
.announcements .announcements-list ul li .views {
  display: inline-block;
  margin-right: 10px;
  color: #727272;
  text-align: center;
}
/* line 1344, ../sass/app.scss */
.announcements .announcements-list ul li .views span {
  display: block;
  font-size: 16px;
  font-weight: 700;
}
/* line 1351, ../sass/app.scss */
.announcements .announcements-list ul li .announcements-detail {
  display: inline-block;
}
/* line 1353, ../sass/app.scss */
.announcements .announcements-list ul li .announcements-detail span {
  font-size: 12px;
  color: #727272;
}
/* line 1358, ../sass/app.scss */
.announcements .announcements-list ul li .announcements-detail span strong {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  display: inline-block;
}
/* line 1367, ../sass/app.scss */
ul .email-log {
  border-left: #03a9f4 solid 4px;
}

ul .update-log {
  border-left: #ff9501 solid 4px;
}

ul .add-log {
  border-left: #009900 solid 4px;
}

ul .delete-log {
  border-left: #cc0000 solid 4px;
}

/*Dashboard 2 styles*/
/* line 1430, ../sass/app.scss */
#dashboard2 .daily-visitor {
  margin-top: 0px;
}

/* line 1434, ../sass/app.scss */
.widget-2 {
  color: #FFF;
  padding: 17px;
  margin: 30px 0px;
  text-align: center;
}
/* line 1439, ../sass/app.scss */
.widget-2 h4 {
  font-size: 16px;
}
/* line 1442, ../sass/app.scss */
.widget-2 img {
  padding: 10px 0px;
}
/* line 1445, ../sass/app.scss */
.widget-2 h3 {
  font-size: 24px;
}

/* line 1449, ../sass/app.scss */
.user-2 {
  background-color: #ff4a43;
}

/* line 1452, ../sass/app.scss */
.clients-2 {
  background-color: #22beef;
}

/* line 1455, ../sass/app.scss */
.new-project-2 {
  background-color: #a2d200;
}

/* line 1458, ../sass/app.scss */
.old-project-2 {
  background-color: #8e44ad;
}

/* line 1461, ../sass/app.scss */
.tab-list-2 .nav-tabs > li {
  width: 33.33%;
  display: inline-block;
}

/* line 1465, ../sass/app.scss */
.tab-list-2 .nav-tabs > li > a {
  color: #FFF;
  border: none;
  padding: 15px 0px;
  font-size: 15px;
  font-weight: 600;
  display: block;
  text-align: center;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  box-shadow: inset 0 0 0 99999px rgba(255, 255, 255, 0.2);
  margin: 0px;
}

/* line 1481, ../sass/app.scss */
.tab-list-2 .nav-tabs li.active .comment-tab {
  background-color: #22beef;
  box-shadow: none;
}
/* line 1485, ../sass/app.scss */
.tab-list-2 .nav-tabs li.active .article-tab {
  background-color: #a2d200;
  box-shadow: none;
}
/* line 1489, ../sass/app.scss */
.tab-list-2 .nav-tabs li.active .user-tab {
  background-color: #8e44ad;
  box-shadow: none;
}
/* line 1496, ../sass/app.scss */
.tab-list-2 .comment-tab {
  background-color: #22beef;
}
/* line 1498, ../sass/app.scss */
.tab-list-2 .comment-tab:hover {
  background-color: #22beef;
  box-shadow: none;
}
/* line 1503, ../sass/app.scss */
.tab-list-2 .article-tab {
  background-color: #a2d200;
}
/* line 1505, ../sass/app.scss */
.tab-list-2 .article-tab:hover {
  background-color: #a2d200;
  box-shadow: none;
}
/* line 1510, ../sass/app.scss */
.tab-list-2 .user-tab {
  background-color: #8e44ad;
}
/* line 1512, ../sass/app.scss */
.tab-list-2 .user-tab:hover {
  background-color: #8e44ad;
  box-shadow: none;
}

/* line 1519, ../sass/app.scss */
.tab-list-2 .nav-tabs > li.active > a, .tab-list-2 .nav-tabs > li.active > a:focus, .tab-list-2 .nav-tabs > li.active > a:hover {
  color: #000;
  border: none;
}

/* line 1524, ../sass/app.scss */
.tab-list-2 .tab-content {
  background-color: #fafafa;
}
/* line 1528, ../sass/app.scss */
.tab-list-2 .tab-content ul li {
  padding: 10px;
  display: table;
  color: #000;
}
/* line 1532, ../sass/app.scss */
.tab-list-2 .tab-content ul li img {
  display: table-cell;
  vertical-align: middle;
  margin-right: 20px;
}
/* line 1537, ../sass/app.scss */
.tab-list-2 .tab-content ul li .media-content {
  display: table-cell;
  vertical-align: middle;
}
/* line 1540, ../sass/app.scss */
.tab-list-2 .tab-content ul li .media-content h3 {
  font-size: 14px;
  font-weight: 600;
  margin: 0px;
}
/* line 1545, ../sass/app.scss */
.tab-list-2 .tab-content ul li .media-content p {
  font-size: 14px;
  margin-top: 10px;
  line-height: 22px;
}

/*Trending widget*/
/* line 1557, ../sass/app.scss */
.trending-2 .trending-head {
  color: #1b93e1;
  font-size: 18px;
  text-align: center;
  padding: 15px 0px;
  border-bottom: #d1d1d1 solid 1px;
}
/* line 1566, ../sass/app.scss */
.trending-2 .trending-list ul li {
  display: inline-block;
  padding: 15px 10px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
}
/* line 1572, ../sass/app.scss */
.trending-2 .trending-list ul li .pull-left {
  color: #000;
}
/* line 1576, ../sass/app.scss */
.trending-2 .trending-list ul li span i {
  margin-right: 8px;
}

/* line 1588, ../sass/app.scss */
.admin-widget-2 {
  margin: 30px 0px;
}
/* line 1590, ../sass/app.scss */
.admin-widget-2 .widget-head {
  padding: 15px 10px;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  height: auto;
}
/* line 1596, ../sass/app.scss */
.admin-widget-2 .content-list-2 {
  border: #e7e7e7 solid 1px;
  border-bottom: none;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
/* line 1603, ../sass/app.scss */
.admin-widget-2 .content-list-2 ul li {
  border-bottom: #e7e7e7 solid 1px;
  padding: 10px 10px;
  display: block;
}
/* line 1607, ../sass/app.scss */
.admin-widget-2 .content-list-2 ul li .list-image {
  display: inline-block;
  position: relative;
  margin-right: 10px;
  float: left;
}
/* line 1612, ../sass/app.scss */
.admin-widget-2 .content-list-2 ul li .list-image i {
  position: absolute;
  width: 10px;
  height: 10px;
  top: -1px;
  left: 2px;
  border: #FFF solid 1px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
}
/* line 1622, ../sass/app.scss */
.admin-widget-2 .content-list-2 ul li .list-image .online {
  background-color: #75A300;
}
/* line 1625, ../sass/app.scss */
.admin-widget-2 .content-list-2 ul li .list-image .offline {
  background-color: #5c5c5c;
}
/* line 1630, ../sass/app.scss */
.admin-widget-2 .content-list-2 ul li .list-detail-2 {
  display: inline-block;
}
/* line 1632, ../sass/app.scss */
.admin-widget-2 .content-list-2 ul li .list-detail-2 span {
  display: block;
}
/* line 1635, ../sass/app.scss */
.admin-widget-2 .content-list-2 ul li .list-detail-2 .box-heading-2 {
  font-size: 12px;
  font-weight: 600;
  color: #000;
}
/* line 1640, ../sass/app.scss */
.admin-widget-2 .content-list-2 ul li .list-detail-2 .box-description-2 {
  line-height: 24px;
  font-size: 13px;
  font-weight: 600;
  color: #1b93e1;
}
/* line 1645, ../sass/app.scss */
.admin-widget-2 .content-list-2 ul li .list-detail-2 .box-time-2 {
  font-size: 12px;
  color: #a1a1a1;
}

/* line 1658, ../sass/app.scss */
.projects-2 .content-list-2 ul li {
  padding: 15px 10px;
}
/* line 1660, ../sass/app.scss */
.projects-2 .content-list-2 ul li .list-detail-2 {
  width: 75%;
}
/* line 1662, ../sass/app.scss */
.projects-2 .content-list-2 ul li .list-detail-2 .box-description-2 {
  line-height: 31px;
}
/* line 1666, ../sass/app.scss */
.projects-2 .content-list-2 ul li .pull-right {
  width: 25%;
}
/* line 1668, ../sass/app.scss */
.projects-2 .content-list-2 ul li .pull-right span {
  display: block;
  padding: 3px 2px;
  display: block;
  color: #FFF;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
}

/* line 1684, ../sass/app.scss */
.sparkline-data {
  margin: 30px 0px;
}
/* line 1687, ../sass/app.scss */
.sparkline-data ul li {
  border-bottom: #e0e0e0 solid 1px;
  text-align: center;
  padding: 20px 10px;
  display: table;
  width: 100%;
}
/* line 1693, ../sass/app.scss */
.sparkline-data ul li .working-hour {
  display: table-cell;
  vertical-align: middle;
}
/* line 1696, ../sass/app.scss */
.sparkline-data ul li .working-hour span {
  font-size: 14px;
  color: #a3aebb;
}
/* line 1699, ../sass/app.scss */
.sparkline-data ul li .working-hour span strong {
  font-size: 14px;
  font-weight: 700;
  color: #4c5f70;
  display: inline-block;
  margin-bottom: 5px;
}
/* line 1708, ../sass/app.scss */
.sparkline-data ul li #sparkline {
  display: table-cell;
}
/* line 1714, ../sass/app.scss */
.sparkline-data ul .peoples-2 {
  border: none;
}
/* line 1716, ../sass/app.scss */
.sparkline-data ul .peoples-2 .working-hour {
  width: 41%;
}
/* line 1719, ../sass/app.scss */
.sparkline-data ul .peoples-2 .all-peoples {
  display: table-cell;
  width: 79%;
  padding-top: 30px;
}
/* line 1723, ../sass/app.scss */
.sparkline-data ul .peoples-2 .all-peoples img {
  margin: 2px;
  max-height: 25px;
  display: inline-block;
}

/*leades table*/
/* line 1745, ../sass/app.scss */
.leads-table table thead tr th {
  background-color: #f8f8f8;
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  padding: 28px 0px;
}
/* line 1756, ../sass/app.scss */
.leads-table .table-bordered > tbody > tr > td, .leads-table .table-bordered > tbody > tr > th, .leads-table .table-bordered > tfoot > tr > td, .leads-table .table-bordered > tfoot > tr > th, .leads-table .table-bordered > thead > tr > td, .leads-table .table-bordered > thead > tr > th {
  border: #e2e2e2 solid 1px;
  text-align: center;
  font-size: 14px;
}
/* line 1761, ../sass/app.scss */
.leads-table .table-striped > tbody > tr td {
  padding: 22px 0px;
}
/* line 1765, ../sass/app.scss */
.leads-table .table-striped > tbody > tr td.bold {
  font-weight: 700;
}
/* line 1769, ../sass/app.scss */
.leads-table .table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #fafafa;
}
/* line 1772, ../sass/app.scss */
.leads-table .table-striped > tbody > tr:nth-of-type(odd) td {
  padding: 8px 0px;
}

/*google map*/
/* line 1779, ../sass/app.scss */
.admin-2-map {
  margin: 10px 0px;
}
/* line 1781, ../sass/app.scss */
.admin-2-map #map2 {
  width: 100%;
  height: 400px;
}

/* line 1787, ../sass/app.scss */
.issues-2 {
  margin: 10px 0px;
}
/* line 1790, ../sass/app.scss */
.issues-2 .widget-head {
  background-color: #8e44ad;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

/*action modal*/
/* line 1799, ../sass/app.scss */
#action-modal .modal-content {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  font-weight: 600;
  font-size: 14px;
}
/* line 1803, ../sass/app.scss */
#action-modal .modal-content label {
  margin-bottom: 8px;
}
/* line 1806, ../sass/app.scss */
#action-modal .modal-content .form-control {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
/* line 1809, ../sass/app.scss */
#action-modal .modal-content input {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
/* line 1812, ../sass/app.scss */
#action-modal .modal-content select {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
/* line 1815, ../sass/app.scss */
#action-modal .modal-content button {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

/*Mail box 1*/
/* line 1825, ../sass/app.scss */
.mail-box1 {
  background-color: #edf0f5;
  padding: 10px;
}
/* line 1829, ../sass/app.scss */
.mail-box1 .compose-btn a {
  background-color: #1b93e1;
  color: #FFF;
  padding: 12px 5px;
  font-size: 18px;
  font-weight: 600;
  display: block;
  text-transform: capitalize;
  text-align: center;
  text-decoration: none;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
/* line 1842, ../sass/app.scss */
.mail-box1 .compose-btn a:focus {
  outline: none;
}
/* line 1845, ../sass/app.scss */
.mail-box1 .compose-btn a:hover {
  background-color: #1B7FC9;
}

/* line 1854, ../sass/app.scss */
.mail-menu ul {
  margin: 15px 0px;
}
/* line 1858, ../sass/app.scss */
.mail-menu ul li a {
  display: block;
  padding: 8px 10px;
  font-size: 15px;
  margin-bottom: 10px;
  text-decoration: none;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
/* line 1866, ../sass/app.scss */
.mail-menu ul li a:focus {
  outline: none;
}
/* line 1869, ../sass/app.scss */
.mail-menu ul li a:hover {
  color: #FFF;
  background-color: #1b93e1;
}
/* line 1875, ../sass/app.scss */
.mail-menu ul li a i {
  margin-right: 6px;
}
/* line 1880, ../sass/app.scss */
.mail-menu ul li .defaultclr {
  color: #6a6c6f;
}
/* line 1886, ../sass/app.scss */
.mail-menu .mail-label {
  border-bottom: #CBCED3 solid 1px;
  border-top: #CBCED3 solid 1px;
  padding: 10px 5px 10px 5px;
  margin-top: 30px;
}
/* line 1893, ../sass/app.scss */
.mail-menu .mail-label li h2 {
  font-size: 18px;
  font-weight: 600;
  color: #666;
  padding-bottom: 18px;
}

/* line 1905, ../sass/app.scss */
.label-dot {
  display: inline-block;
  margin-top: 3px;
  height: 10px;
  width: 10px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
}

/* line 1915, ../sass/app.scss */
.email-action-list .email-action-btn {
  margin: 0px;
}
/* line 1917, ../sass/app.scss */
.email-action-list .email-action-btn li {
  float: left;
  display: inline-block;
  margin-right: 20px;
}
/* line 1921, ../sass/app.scss */
.email-action-list .email-action-btn li a {
  background-color: #FFF;
  display: block;
  color: #6a6c6f;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  text-align: center;
  padding: 10px 15px;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
  outline: none;
}
/* line 1934, ../sass/app.scss */
.email-action-list .email-action-btn li a:hover {
  background-color: #b3b3b3;
}
/* line 1939, ../sass/app.scss */
.email-action-list .email-action-btn li .dropdown-menu li {
  width: 100% !important;
  margin: 0px;
}
/* line 1942, ../sass/app.scss */
.email-action-list .email-action-btn li .dropdown-menu li a {
  text-align: left;
  padding: 5px 10px;
}
/* line 1945, ../sass/app.scss */
.email-action-list .email-action-btn li .dropdown-menu li a:hover {
  background-color: #eeeeee;
}
/* line 1952, ../sass/app.scss */
.email-action-list .email-action-btn li.noMargin {
  margin-right: 0px;
}

/* line 1958, ../sass/app.scss */
.email-list ul, .contact-list ul {
  margin: 10px 0px;
  background-color: #FFF;
}
/* line 1961, ../sass/app.scss */
.email-list ul li, .contact-list ul li {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: #edf0f5 solid 1px;
  padding: 0px 10px;
}
/* line 1969, ../sass/app.scss */
.email-list ul li input, .contact-list ul li input {
  width: 20px;
  height: 20px;
}
/* line 1973, ../sass/app.scss */
.email-list ul li a, .contact-list ul li a {
  width: 100%;
  padding: 10px 10px;
  text-decoration: none;
  outline: none;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  color: #333;
  display: table-cell;
}
/* line 1982, ../sass/app.scss */
.email-list ul li a .sender-name, .contact-list ul li a .sender-name, .email-list ul li a .contact-list .contact-name, .contact-list ul li a .contact-name {
  display: table-cell;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 600;
  padding: 0px 20px;
  width: 150px;
}
/* line 1990, ../sass/app.scss */
.email-list ul li a .email-detail, .contact-list ul li a .email-detail, .email-list ul li a .contact-list .contact-email, .contact-list ul li a .contact-email {
  display: table-cell;
  vertical-align: middle;
  font-size: 14px;
  color: #6a6c6f;
}
/* line 1997, ../sass/app.scss */
.email-list ul li:hover, .contact-list ul li:hover {
  background-color: #e6e6e6;
}

/* line 2005, ../sass/app.scss */
.breadcrumb li a {
  color: #c1c1c1;
}
/* line 2008, ../sass/app.scss */
.breadcrumb li.active {
  color: #FFF;
}

/* line 2014, ../sass/app.scss */
.email-detail-page {
  background-color: #FFF;
  padding: 10px;
  margin: 14px 0px;
}
/* line 2019, ../sass/app.scss */
.email-detail-page .email-header h2 {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 15px;
  border-bottom: #CBCED3 solid 1px;
}
/* line 2025, ../sass/app.scss */
.email-detail-page .email-header .email-sender {
  display: table;
  padding: 10px 0px;
  width: 100%;
  border-bottom: #CBCED3 solid 1px;
}
/* line 2029, ../sass/app.scss */
.email-detail-page .email-header .email-sender img {
  display: table-cell;
  vertical-align: middle;
  margin-right: 15px;
}
/* line 2034, ../sass/app.scss */
.email-detail-page .email-header .email-sender .sender-name-email {
  display: table-cell;
  vertical-align: middle;
}
/* line 2037, ../sass/app.scss */
.email-detail-page .email-header .email-sender .sender-name-email .sender-name, .email-detail-page .email-header .email-sender .sender-name-email .contact-list .contact-name, .contact-list .email-detail-page .email-header .email-sender .sender-name-email .contact-name {
  font-size: 14px;
  font-weight: 600;
  display: block;
  color: #333;
}
/* line 2044, ../sass/app.scss */
.email-detail-page .email-header .email-sender .sender-name-email .sender-email {
  font-size: 14px;
  display: block;
  color: #666;
}
/* line 2048, ../sass/app.scss */
.email-detail-page .email-header .email-sender .sender-name-email .sender-email strong {
  font-weight: 700;
  color: #000;
  display: inline-block;
  margin-top: 9px;
}

/* line 2062, ../sass/app.scss */
.email-text {
  margin-top: 20px;
}
/* line 2064, ../sass/app.scss */
.email-text p {
  font-size: 14px;
  line-height: 22px;
  color: #666;
  margin-bottom: 25px;
}

/* line 2071, ../sass/app.scss */
.reply-box {
  margin-top: 50px;
}
/* line 2073, ../sass/app.scss */
.reply-box textarea {
  height: 180px;
  resize: none;
  width: 100%;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

/* line 2080, ../sass/app.scss */
.send-email {
  padding: 10px 20px;
  color: #FFF;
  background: #1b93e1;
  border: none;
  float: right;
  margin: 15px 0px;
  text-align: center;
  font-size: 15px;
}

/* line 2094, ../sass/app.scss */
#compose-modal .modal-content, #contact-modal .modal-content, #add-contact .modal-content {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
/* line 2097, ../sass/app.scss */
#compose-modal .modal-title, #contact-modal .modal-title, #add-contact .modal-title {
  font-size: 16px;
  font-weight: 600;
  color: #666;
}
/* line 2102, ../sass/app.scss */
#compose-modal label, #contact-modal label, #add-contact label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #666;
}
/* line 2108, ../sass/app.scss */
#compose-modal .form-control, #contact-modal .form-control, #add-contact .form-control {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
/* line 2111, ../sass/app.scss */
#compose-modal textarea, #contact-modal textarea, #add-contact textarea {
  height: 100px;
  resize: none;
}
/* line 2116, ../sass/app.scss */
#compose-modal .modal-footer button, #contact-modal .modal-footer button, #add-contact .modal-footer button {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  font-size: 22px;
  border: none;
  color: #FFF;
  padding: 7px 20px;
}

/*email style 2*/
/* line 2128, ../sass/app.scss */
.email-style-2 {
  background-color: #FFF;
  padding: 0px;
}
/* line 2131, ../sass/app.scss */
.email-style-2 .email-action-list {
  padding: 10px;
  background: #1b93e1;
}
/* line 2135, ../sass/app.scss */
.email-style-2 .email2-list {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.19), 0 2px 8px 0 rgba(0, 0, 0, 0.16);
}
/* line 2140, ../sass/app.scss */
.email-style-2 .email2-list ul li a {
  display: block;
  padding: 15px 8px;
  border-bottom: 1px solid #e0e0e0;
  color: #333;
  width: 100%;
  text-decoration: none;
  outline: none;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
/* line 2150, ../sass/app.scss */
.email-style-2 .email2-list ul li a span {
  display: inline-block;
  float: left;
}
/* line 2154, ../sass/app.scss */
.email-style-2 .email2-list ul li a p {
  display: inline-block;
  line-height: 30px;
}
/* line 2158, ../sass/app.scss */
.email-style-2 .email2-list ul li a .sender-name, .email-style-2 .email2-list ul li a .contact-list .contact-name, .contact-list .email-style-2 .email2-list ul li a .contact-name {
  font-size: 15px;
  color: #8a8a8a;
  padding-left: 10px;
}
/* line 2162, ../sass/app.scss */
.email-style-2 .email2-list ul li a .sender-name strong, .email-style-2 .email2-list ul li a .contact-list .contact-name strong, .contact-list .email-style-2 .email2-list ul li a .contact-name strong {
  display: block;
  color: #333;
  font-weight: 600;
  margin-bottom: 3px;
}
/* line 2171, ../sass/app.scss */
.email-style-2 .email2-list ul li a:hover {
  background-color: #dbeff8;
}
/* line 2175, ../sass/app.scss */
.email-style-2 .email2-list ul li .active {
  background-color: #dbeff8;
}

/* line 2184, ../sass/app.scss */
.text-avatar {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  letter-spacing: 1px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
}

/*comopose page*/
/* line 2196, ../sass/app.scss */
.compose-page {
  margin: 30px 0px;
}
/* line 2202, ../sass/app.scss */
.compose-page form input.form-control {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  border-top: none;
  border-right: none;
  border-left: none;
  box-shadow: none !important;
  margin-bottom: 30px;
}
/* line 2210, ../sass/app.scss */
.compose-page form textarea {
  resize: none;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  height: 190px;
}

/*alerts page*/
/* line 2221, ../sass/app.scss */
.alerts-page {
  padding: 30px 0px;
}

/* line 2225, ../sass/app.scss */
.h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0px 20px 0px;
  color: #333;
}

/* line 2232, ../sass/app.scss */
.alert {
  font-size: 14px;
}
/* line 2234, ../sass/app.scss */
.alert strong {
  font-weight: 700;
}
/* line 2236, ../sass/app.scss */
.alert strong i {
  margin-right: 7px;
}

/* line 2244, ../sass/app.scss */
.normal-text {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 20px;
  color: #333;
}

/* line 2250, ../sass/app.scss */
hr {
  border-color: #bec1c6;
}

/* line 2253, ../sass/app.scss */
.custom-simple-alert-success {
  background-color: #0DFF8D;
  padding: 18px 15px;
  color: #00672d;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

/* line 2259, ../sass/app.scss */
.custom-simple-alert-info {
  background-color: #4CD8FF;
  padding: 18px 15px;
  color: #007599;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

/* line 2265, ../sass/app.scss */
.custom-simple-alert-warning {
  background-color: #ffdeb4;
  padding: 18px 15px;
  color: #6b5647;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

/* line 2272, ../sass/app.scss */
.custom-simple-alert-danger {
  background-color: #e2786e;
  padding: 18px 15px;
  color: #A25151;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

/*dark alerts*/
/* line 2282, ../sass/app.scss */
.dark-alerts .close {
  opacity: 0.9;
}

/* line 2286, ../sass/app.scss */
.custom-dark-alert-success {
  background-color: #00a33d;
  padding: 18px 35px 18px 10px;
  color: #FFF;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

/* line 2292, ../sass/app.scss */
.custom-dark-alert-info {
  background-color: #1b93e1;
  padding: 18px 35px 18px 10px;
  color: #FFF;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

/* line 2299, ../sass/app.scss */
.custom-dark-alert-warning {
  background-color: #ff9501;
  padding: 18px 35px 18px 10px;
  color: #FFF;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

/* line 2307, ../sass/app.scss */
.custom-dark-alert-danger {
  background-color: #e74c3c;
  padding: 18px 35px 18px 10px;
  color: #FFF;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

/*buttons page*/
/* line 2315, ../sass/app.scss */
.buttons-page {
  padding: 30px 0px;
}

/* line 2318, ../sass/app.scss */
.btn {
  margin-bottom: 20px;
  padding: 8px 25px;
  font-size: 14px;
  font-weight: 600;
  outline: none;
}

.btn-no-margin {
  margin-bottom: 0;
}
/* line 2324, ../sass/app.scss */
.btn i {
  font-size: 18px;
  vertical-align: middle;
}

/* line 2330, ../sass/app.scss */
.btn-pink {
  background-color: #ff63e3;
  border-color: #ff63e3;
  color: #FFF;
}
/* line 2334, ../sass/app.scss */
.btn-pink:hover {
  color: #fff;
  background-color: #d45ab8;
  border-color: #ba549e;
}
/* line 2339, ../sass/app.scss */
.btn-pink:focus {
  color: #fff;
}

/* line 2343, ../sass/app.scss */
.btn-Purple {
  background-color: #7a007a;
  border-color: #7a007a;
  color: #FFF;
}
/* line 2347, ../sass/app.scss */
.btn-Purple:hover {
  color: #fff;
  background-color: #620062;
  border-color: #5A005A;
}
/* line 2352, ../sass/app.scss */
.btn-Purple:focus {
  color: #fff;
}

/* line 2356, ../sass/app.scss */
.btn-inverse {
  background-color: #191919;
  border-color: #191919;
  color: #FFF;
}
/* line 2360, ../sass/app.scss */
.btn-inverse:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
/* line 2365, ../sass/app.scss */
.btn-inverse:focus {
  color: #fff;
}

/* line 2369, ../sass/app.scss */
.btn-turquoise {
  background-color: #40E0D0;
  border-color: #40E0D0;
  color: #FFF;
}
/* line 2373, ../sass/app.scss */
.btn-turquoise:hover {
  color: #fff;
  background-color: #38C3B3;
  border-color: #32A999;
}
/* line 2378, ../sass/app.scss */
.btn-turquoise:focus {
  color: #fff;
}

/* line 2382, ../sass/app.scss */
.rounded {
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  border-radius: 18px;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

/*outlined buttons*/
/* line 2388, ../sass/app.scss */
.outlined {
  background-color: transparent;
}

/* line 2392, ../sass/app.scss */
.btn-primary.outlined {
  color: #1b93e1;
}
/* line 2394, ../sass/app.scss */
.btn-primary.outlined:hover {
  color: #FFF;
}
/* line 2397, ../sass/app.scss */
.btn-primary.outlined:focus {
  color: #FFF;
}

/* line 2402, ../sass/app.scss */
.btn-info.outlined {
  color: #6abff6;
}
/* line 2404, ../sass/app.scss */
.btn-info.outlined:hover {
  color: #FFF;
}
/* line 2407, ../sass/app.scss */
.btn-info.outlined:focus {
  color: #FFF;
}

/* line 2411, ../sass/app.scss */
.btn-success.outlined {
  color: #54a854;
}
/* line 2413, ../sass/app.scss */
.btn-success.outlined:hover {
  color: #FFF;
}
/* line 2416, ../sass/app.scss */
.btn-success.outlined:focus {
  color: #FFF;
}

/* line 2420, ../sass/app.scss */
.btn-danger.outlined {
  color: #e74c3c;
}
/* line 2422, ../sass/app.scss */
.btn-danger.outlined:hover {
  color: #FFF;
}
/* line 2425, ../sass/app.scss */
.btn-danger.outlined:focus {
  color: #FFF;
}

/* line 2429, ../sass/app.scss */
.btn-warning.outlined {
  color: #ec9419;
}
/* line 2431, ../sass/app.scss */
.btn-warning.outlined:hover {
  color: #FFF;
}

/* line 2435, ../sass/app.scss */
.btn-pink.outlined {
  color: #ff63e3;
}
/* line 2437, ../sass/app.scss */
.btn-pink.outlined:hover {
  color: #FFF;
}

/* line 2441, ../sass/app.scss */
.btn-Purple.outlined {
  color: #7a007a;
}
/* line 2443, ../sass/app.scss */
.btn-Purple.outlined:hover {
  color: #FFF;
}

/* line 2447, ../sass/app.scss */
.btn-inverse.outlined {
  color: #191919;
}
/* line 2449, ../sass/app.scss */
.btn-inverse.outlined:hover {
  color: #FFF;
}

/* line 2453, ../sass/app.scss */
.btn-turquoise.outlined {
  color: #40E0D0;
}
/* line 2455, ../sass/app.scss */
.btn-turquoise.outlined:hover {
  color: #FFF;
}

/* line 2460, ../sass/app.scss */
.btn-lg {
  padding: 12px 35px;
  font-size: 22px;
}

/* line 2464, ../sass/app.scss */
.btn-sm {
  padding: 6px 18px;
  font-size: 13px;
}

/* line 2468, ../sass/app.scss */
.btn-xs {
  padding: 2px 16px;
  font-size: 13px;
  font-weight: 400;
}

/*animation page*/
/* line 2474, ../sass/app.scss */
.animation-page {
  margin: 30px 0px;
}

/* line 2479, ../sass/app.scss */
.h2 {
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0px;
}

/* line 2484, ../sass/app.scss */
.panel-heading {
  background-color: #e6e6e6;
}

/* line 2487, ../sass/app.scss */
.animation-box {
  width: 100%;
}
/* line 2489, ../sass/app.scss */
.animation-box .wow {
  max-width: 350px;
}

/*breadcrumbs page*/
/* line 2499, ../sass/app.scss */
.breadcrumbs-page {
  padding: 30px 0px;
}

/* line 2502, ../sass/app.scss */
.breadcrumb {
  background-color: #333;
  padding: 15px 15px;
}

/* line 2506, ../sass/app.scss */
.breadcrumb-box {
  width: 100%;
}
/* line 2508, ../sass/app.scss */
.breadcrumb-box ul {
  padding: 15px 10px;
  list-style: none;
  margin-bottom: 30px;
}
/* line 2515, ../sass/app.scss */
.breadcrumb-box ul li {
  display: inline-block;
  font-size: 16px;
}
/* line 2518, ../sass/app.scss */
.breadcrumb-box ul li a {
  color: #f8f8f8;
  font-size: 16px;
  font-weight: 600;
}
/* line 2523, ../sass/app.scss */
.breadcrumb-box ul li i {
  color: #f8f8f8;
  padding: 0px 20px;
}
/* line 2529, ../sass/app.scss */
.breadcrumb-box ul li.active {
  color: #FFF;
  font-size: 18px;
  font-weight: 700;
}

/*color library page*/
/* line 2539, ../sass/app.scss */
.color-page {
  margin: 30px 0px;
}

/* line 2543, ../sass/app.scss */
.color-box {
  margin: 0px 0px 30px 0px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  display: block;
}
/* line 2547, ../sass/app.scss */
.color-box .color-display {
  padding: 40px 0px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
/* line 2554, ../sass/app.scss */
.color-box .color-title {
  padding: 10px;
  border: rgba(0, 0, 0, 0.1) solid 1px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
}

/* line 2563, ../sass/app.scss */
.IndianRed {
  background-color: #CD5C5C;
  color: #FFF;
}

/* line 2567, ../sass/app.scss */
.LightCoral {
  background-color: #F08080;
  color: #FFF;
}

/* line 2571, ../sass/app.scss */
.Salmon {
  background-color: #FA8072;
  color: #FFF;
}

/* line 2575, ../sass/app.scss */
.DarkSalmon {
  background-color: #E9967A;
  color: #FFF;
}

/* line 2579, ../sass/app.scss */
.LightSalmon {
  background-color: #FFA07A;
  color: #FFF;
}

/* line 2583, ../sass/app.scss */
.Crimson {
  background-color: #DC143C;
  color: #FFF;
}

/* line 2587, ../sass/app.scss */
.Pink {
  background-color: #FFC0CB;
  color: #333;
}

/* line 2591, ../sass/app.scss */
.LightPink {
  background-color: #FFB6C1;
  color: #333;
}

/* line 2595, ../sass/app.scss */
.HotPink {
  background-color: #FF69B4;
  color: #333;
}

/* line 2599, ../sass/app.scss */
.DeepPink {
  background-color: #FF1493;
  color: #333;
}

/* line 2603, ../sass/app.scss */
.MediumVioletRed {
  background-color: #C71585;
  color: #FFF;
}

/* line 2607, ../sass/app.scss */
.PaleVioletRed {
  background-color: #DB7093;
  color: #FFF;
}

/* line 2612, ../sass/app.scss */
.Lavender {
  background-color: #E6E6FA;
  color: #333;
}

/* line 2616, ../sass/app.scss */
.Thistle {
  background-color: #D8BFD8;
  color: #333;
}

/* line 2620, ../sass/app.scss */
.Plum {
  background-color: #DDA0DD;
  color: #333;
}

/* line 2624, ../sass/app.scss */
.Violet {
  background-color: #EE82EE;
  color: #333;
}

/* line 2628, ../sass/app.scss */
.Orchid {
  background-color: #DA70D6;
  color: #333;
}

/* line 2632, ../sass/app.scss */
.DarkOrchid {
  background-color: #9932CC;
  color: #FFF;
}

/* line 2636, ../sass/app.scss */
.Purple {
  background-color: #800080;
  color: #FFF;
}

/* line 2640, ../sass/app.scss */
.SpringGreen {
  background-color: #00FF7F;
  color: #333;
}

/* line 2644, ../sass/app.scss */
.MediumSeaGreen {
  background-color: #3CB371;
  color: #FFF;
}

/* line 2648, ../sass/app.scss */
.SeaGreen {
  background-color: #2E8B57;
  color: #FFF;
}

/* line 2652, ../sass/app.scss */
.ForestGreen {
  background-color: #228B22;
  color: #FFF;
}

/* line 2656, ../sass/app.scss */
.Green {
  background-color: #008000;
  color: #FFF;
}

/* line 2660, ../sass/app.scss */
.DarkGreen {
  background-color: #006400;
  color: #FFF;
}

/* line 2664, ../sass/app.scss */
.DarkBlue {
  background-color: #00008B;
  color: #FFF;
}

/* line 2668, ../sass/app.scss */
.MediumBlue {
  background-color: #0000CD;
  color: #FFF;
}

/* line 2672, ../sass/app.scss */
.Blue {
  background-color: #0000FF;
  color: #FFF;
}

/* line 2676, ../sass/app.scss */
.RoyalBlue {
  background-color: #4169E1;
  color: #FFF;
}

/* line 2680, ../sass/app.scss */
.MediumSlateBlue {
  background-color: #7B68EE;
  color: #FFF;
}

/* line 2684, ../sass/app.scss */
.CornflowerBlue {
  background-color: #6495ED;
  color: #FFF;
}

/* line 2688, ../sass/app.scss */
.Gainsboro {
  background-color: #DCDCDC;
  color: #333;
}

/* line 2692, ../sass/app.scss */
.LightGrey {
  background-color: #D3D3D3;
  color: #333;
}

/* line 2696, ../sass/app.scss */
.Silver {
  background-color: #C0C0C0;
  color: #333;
}

/* line 2700, ../sass/app.scss */
.dimGray {
  background-color: #A9A9A9;
  color: #333;
}

/* line 2704, ../sass/app.scss */
.Gray {
  background-color: #808080;
  color: #333;
}

/* line 2708, ../sass/app.scss */
.DarkGray {
  background-color: #696969;
  color: #FFF;
}

/*icons page*/
/* line 2713, ../sass/app.scss */
.icons-page {
  margin: 30px 0px;
}

/* line 2717, ../sass/app.scss */
.icon-box ul {
  margin-bottom: 30px;
}
/* line 2719, ../sass/app.scss */
.icon-box ul li {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin: 10px 0px;
  float: left;
  width: 100%;
}
/* line 2729, ../sass/app.scss */
.icon-box ul li i {
  margin-right: 15px;
  width: 40px;
  font-size: 20px;
}

/*Modal page*/
/* line 2741, ../sass/app.scss */
.modal-page {
  margin: 30px 0px;
}

/* line 2745, ../sass/app.scss */
.modal button {
  margin: 0px !important;
}

/* line 2750, ../sass/app.scss */
.modal-header {
  background-color: #edf0f5;
}

/* line 2754, ../sass/app.scss */
.modal-footer {
  background-color: #edf0f5;
}

/* line 2757, ../sass/app.scss 
.modal-body {
  padding: 30px 10px;
}*/
/* line 2760, ../sass/app.scss */
.modal-body form label {
  margin-bottom: 10px;
}

/* line 2767, ../sass/app.scss */
.text-center .modal-footer {
  text-align: center;
}

/* line 2771, ../sass/app.scss */
.modal-full {
  width: 99%;
}

/* line 2774, ../sass/app.scss */
.primary {
  background-color: #1b93e1;
  color: #FFF;
}

/* line 2778, ../sass/app.scss */
.success {
  background-color: #54a854;
  color: #FFF;
}

/* line 2782, ../sass/app.scss */
.info {
  background-color: #6abff6;
  color: #FFF;
}

/* line 2786, ../sass/app.scss */
.danger {
  background-color: #e74c3c;
  color: #FFF;
}

/*navbar page*/
/* line 2792, ../sass/app.scss */
.navbar-page {
  padding: 30px 0px;
}

/* line 2795, ../sass/app.scss */
.navigation-style {
  margin-bottom: 30px;
}
/* line 2798, ../sass/app.scss */
.navigation-style .nav-logo {
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  color: #FFF;
  padding: 25px 10px;
  margin-right: 10px;
  text-decoration: none;
  outline: none;
  display: block;
}
/* line 2809, ../sass/app.scss */
.navigation-style .nav-logo:hover {
  opacity: 0.8;
}
/* line 2816, ../sass/app.scss */
.navigation-style ul li {
  display: inline-block;
}
/* line 2819, ../sass/app.scss */
.navigation-style ul li a {
  color: #FFF;
  padding: 25px 15px;
  display: block;
  font-size: 15px;
  font-weight: 600;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  text-decoration: none;
  outline: none;
}
/* line 2828, ../sass/app.scss */
.navigation-style ul li a i {
  font-size: 18px;
  margin: 0px 10px;
}
/* line 2834, ../sass/app.scss */
.navigation-style ul li a:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
/* line 2837, ../sass/app.scss */
.navigation-style ul li a:focus {
  background-color: rgba(0, 0, 0, 0.2);
}
/* line 2846, ../sass/app.scss */
.navigation-style ul .dropdown .dropdown-menu li {
  display: block;
}
/* line 2848, ../sass/app.scss */
.navigation-style ul .dropdown .dropdown-menu li a {
  display: block;
  width: 100%;
  color: #333;
  padding: 5px 5px;
  font-weight: 400;
  font-size: 14px;
}
/* line 2863, ../sass/app.scss */
.navigation-style .center-nav li a {
  padding: 8px 20px;
}

/* line 2870, ../sass/app.scss */
.logo-center {
  text-align: center;
}
/* line 2873, ../sass/app.scss */
.logo-center .nav-logo {
  display: inline-block;
}

/*pagination page*/
/* line 2880, ../sass/app.scss */
.pagination-page {
  margin: 30px 0px;
}

/*panels page*/
/* line 2886, ../sass/app.scss */
.panels-page {
  padding: 30px 0px;
}

/* line 2890, ../sass/app.scss */
.panel-heading span {
  display: block;
  font-size: 12px;
  color: #767676;
}

/* line 2897, ../sass/app.scss */
.panel-success {
  border-color: #54a854;
}
/* line 2899, ../sass/app.scss */
.panel-success .panel-heading {
  background-color: #54a854;
  color: #FFF;
}

/* line 2905, ../sass/app.scss */
.panel-primary {
  border-color: #1b93e1;
}
/* line 2907, ../sass/app.scss */
.panel-primary .panel-heading {
  background-color: #1b93e1;
  color: #FFF;
}

/* line 2912, ../sass/app.scss */
.panel-warning {
  border-color: #ec9419;
}
/* line 2914, ../sass/app.scss */
.panel-warning .panel-heading {
  background-color: #ec9419;
  color: #FFF;
}

/* line 2919, ../sass/app.scss */
.panel-danger {
  border-color: #e74c3c;
}
/* line 2921, ../sass/app.scss */
.panel-danger .panel-heading {
  background-color: #e74c3c;
  color: #FFF;
}

/* line 2926, ../sass/app.scss */
.panel-info {
  border-color: #6abff6;
}
/* line 2928, ../sass/app.scss */
.panel-info .panel-heading {
  background-color: #6abff6;
  color: #FFF;
}

/* line 2933, ../sass/app.scss */
.panel-inverse {
  border-color: #191919;
}
/* line 2935, ../sass/app.scss */
.panel-inverse .panel-heading {
  background-color: #191919;
  color: #FFF;
}

/* line 2940, ../sass/app.scss */
.panel-pink {
  border-color: #ff63e3;
}
/* line 2942, ../sass/app.scss */
.panel-pink .panel-heading {
  background-color: #ff63e3;
  color: #FFF;
}

/* line 2947, ../sass/app.scss */
.panel-purple {
  border-color: #7a007a;
}
/* line 2949, ../sass/app.scss */
.panel-purple .panel-heading {
  background-color: #7a007a;
  color: #FFF;
}

/* line 2954, ../sass/app.scss */
.panel-turquoise {
  border-color: #40E0D0;
}
/* line 2956, ../sass/app.scss */
.panel-turquoise .panel-heading {
  background-color: #40E0D0;
  color: #FFF;
}

/* line 2961, ../sass/app.scss */
.panel-success-featured {
  border: none;
  border-top: #54a854 solid 4px;
  border-bottom: #54a854 solid 4px;
}
/* line 2965, ../sass/app.scss */
.panel-success-featured .panel-heading {
  color: #54a854;
  background-color: #FFF;
  border-bottom: rgba(0, 0, 0, 0.2) solid 1px;
}

/* line 2971, ../sass/app.scss */
.panel-primary-featured {
  border: none;
  border-top: #1b93e1 solid 4px;
  border-bottom: #1b93e1 solid 4px;
}
/* line 2975, ../sass/app.scss */
.panel-primary-featured .panel-heading {
  color: #1b93e1;
  background-color: #FFF;
  border-bottom: rgba(0, 0, 0, 0.2) solid 1px;
}

/* line 2981, ../sass/app.scss */
.panel-warning-featured {
  border: none;
  border-top: #ec9419 solid 4px;
  border-bottom: #ec9419 solid 4px;
}
/* line 2985, ../sass/app.scss */
.panel-warning-featured .panel-heading {
  color: #ec9419;
  background-color: #FFF;
  border-bottom: rgba(0, 0, 0, 0.2) solid 1px;
}

/* line 2991, ../sass/app.scss */
.panel-danger-featured {
  border: none;
  border-top: #e74c3c solid 4px;
  border-bottom: #e74c3c solid 4px;
}
/* line 2995, ../sass/app.scss */
.panel-danger-featured .panel-heading {
  color: #e74c3c;
  background-color: #FFF;
  border-bottom: rgba(0, 0, 0, 0.2) solid 1px;
}

/* line 3001, ../sass/app.scss */
.panel-info-featured {
  border: none;
  border-top: #6abff6 solid 4px;
  border-bottom: #6abff6 solid 4px;
}
/* line 3005, ../sass/app.scss */
.panel-info-featured .panel-heading {
  color: #6abff6;
  background-color: #FFF;
  border-bottom: rgba(0, 0, 0, 0.2) solid 1px;
}

/* line 3011, ../sass/app.scss */
.panel-inverse-featured {
  border: none;
  border-top: #191919 solid 4px;
  border-bottom: #191919 solid 4px;
}
/* line 3015, ../sass/app.scss */
.panel-inverse-featured .panel-heading {
  color: #191919;
  background-color: #FFF;
  border-bottom: rgba(0, 0, 0, 0.2) solid 1px;
}

/* line 3021, ../sass/app.scss */
.panel-pink-featured {
  border: none;
  border-top: #ff63e3 solid 4px;
  border-bottom: #ff63e3 solid 4px;
}
/* line 3025, ../sass/app.scss */
.panel-pink-featured .panel-heading {
  color: #ff63e3;
  background-color: #FFF;
  border-bottom: rgba(0, 0, 0, 0.2) solid 1px;
}

/* line 3031, ../sass/app.scss */
.panel-purple-featured {
  border: none;
  border-top: #7a007a solid 4px;
  border-bottom: #7a007a solid 4px;
}
/* line 3035, ../sass/app.scss */
.panel-purple-featured .panel-heading {
  color: #7a007a;
  background-color: #FFF;
  border-bottom: rgba(0, 0, 0, 0.2) solid 1px;
}

/*progress page*/
/* line 3042, ../sass/app.scss */
.progress-page {
  padding: 30px 0px;
}

/* line 3045, ../sass/app.scss */
.progress-xs {
  height: 8px;
}

/* line 3048, ../sass/app.scss */
.progress-sm {
  height: 12px;
}

/* line 3051, ../sass/app.scss */
.progress-md {
  height: 16px;
}

/* line 3054, ../sass/app.scss */
.progress-lg {
  height: 24px;
}

/*tabs page*/
/* line 3060, ../sass/app.scss */
.tabs-page {
  padding: 30px 0px;
}

/* line 3063, ../sass/app.scss */
.tab-box {
  margin-bottom: 25px;
}
/* line 3065, ../sass/app.scss */
.tab-box .normal-text {
  margin-bottom: 7px;
}

/* line 3071, ../sass/app.scss */
.nav-tabs {
  background-color: #FFF;
  border: #ddd solid 1px;
}
/* line 3074, ../sass/app.scss */
.nav-tabs li {
  margin-bottom: 0px;
}
/* line 3076, ../sass/app.scss */
.nav-tabs li a {
  color: #333;
  font-size: 15px;
  font-weight: 600;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  padding: 15px 30px;
  border: none;
  border-bottom: transparent solid 3px;
  outline: none;
  text-decoration: none;
}
/* line 3087, ../sass/app.scss */
.nav-tabs li a:hover {
  border-color: #1b93e1;
  background-color: transparent;
  color: #1b93e1;
}

/* line 3098, ../sass/app.scss */
.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
  border: none;
  color: #1b93e1;
  border-bottom: #1b93e1 solid 3px;
}

/* line 3103, ../sass/app.scss */
.tab-content {
  padding: 0 10px;
  background-color: #FFF;
  color: #333;
  border: 1px solid #ddd;
  border-top: none;
}

/* line 3111, ../sass/app.scss */
.primary-tab {
  background-color: #1b93e1;
}
/* line 3114, ../sass/app.scss */
.primary-tab li a {
  border: none;
  color: #FFF;
}
/* line 3117, ../sass/app.scss */
.primary-tab li a:hover {
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.5);
}

/* line 3124, ../sass/app.scss */
.primary-tab > li.active > a, .primary-tab > li.active > a:focus, .primary-tab > li.active > a:hover {
  border: none;
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.5);
}

/* line 3130, ../sass/app.scss */
.success-tab {
  background-color: #54a854;
}
/* line 3133, ../sass/app.scss */
.success-tab li a {
  border: none;
  color: #FFF;
}
/* line 3136, ../sass/app.scss */
.success-tab li a:hover {
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.5);
}

/* line 3144, ../sass/app.scss */
.success-tab > li.active > a, .success-tab > li.active > a:focus, .success-tab > li.active > a:hover {
  border: none;
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.5);
}

/* line 3150, ../sass/app.scss */
.info-tab {
  background-color: #6abff6;
}
/* line 3154, ../sass/app.scss */
.info-tab li a {
  border: none;
  color: #FFF;
}
/* line 3157, ../sass/app.scss */
.info-tab li a:hover {
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.5);
}

/* line 3165, ../sass/app.scss */
.info-tab > li.active > a, .info-tab > li.active > a:focus, .info-tab > li.active > a:hover {
  border: none;
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.5);
}

/* line 3171, ../sass/app.scss */
.warning-tab {
  background-color: #ec9419;
}
/* line 3175, ../sass/app.scss */
.warning-tab li a {
  border: none;
  color: #FFF;
}
/* line 3178, ../sass/app.scss */
.warning-tab li a:hover {
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.5);
}

/* line 3186, ../sass/app.scss */
.warning-tab > li.active > a, .warning-tab > li.active > a:focus, .warning-tab > li.active > a:hover {
  border: none;
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.5);
}

/* line 3192, ../sass/app.scss */
.danger-tab {
  background-color: #e74c3c;
}
/* line 3196, ../sass/app.scss */
.danger-tab li a {
  border: none;
  color: #FFF;
}
/* line 3199, ../sass/app.scss */
.danger-tab li a:hover {
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.5);
}

/* line 3206, ../sass/app.scss */
.danger-tab > li.active > a, .danger-tab > li.active > a:focus, .danger-tab > li.active > a:hover {
  border: none;
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.5);
}

/* line 3213, ../sass/app.scss */
.inverse-tab {
  background-color: #191919;
}
/* line 3217, ../sass/app.scss */
.inverse-tab li a {
  border: none;
  color: #FFF;
}
/* line 3220, ../sass/app.scss */
.inverse-tab li a:hover {
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.5);
}

/* line 3228, ../sass/app.scss */
.inverse-tab > li.active > a, .inverse-tab > li.active > a:focus, .inverse-tab > li.active > a:hover {
  border: none;
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.5);
}

/*typography page*/
/* line 3326, ../sass/app.scss */
.typography-page {
  margin: 30px 0px;
}

/*widgets page*/
/* line 3331, ../sass/app.scss */
.widget-page {
  padding: 30px 0px;
}

/* line 3335, ../sass/app.scss */
.card-box {
  margin: 30px 0px;
}
/* line 3337, ../sass/app.scss */
.card-box .card {
  margin: 30px 0px;
}

/* line 3342, ../sass/app.scss */
.card {
  background-color: #FFF;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: #ccc solid 1px;
}

/* line 3347, ../sass/app.scss */
.card-block {
  padding: 10px;
}

/*calendar page*/
/* line 3353, ../sass/app.scss */
.calendar-page {
  padding: 30px 0px;
}

/* line 3356, ../sass/app.scss */
#calendar {
  margin: 0 auto;
  width: 100%;
}

/* line 3365, ../sass/app.scss */
#external-events h4 {
  font-weight: 600;
}
/* line 3368, ../sass/app.scss */
#external-events .fc-event {
  padding: 5px 10px;
  text-align: center;
  font-size: 14px;
  margin: 10px 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  cursor: move;
  border: none;
}

/* line 3379, ../sass/app.scss */
.fc-button-group button {
  -moz-border-radius-topleft: 0px;
  -webkit-border-top-left-radius: 0px;
  border-top-left-radius: 0px;
  -moz-border-radius-bottomleft: 0px;
  -webkit-border-bottom-left-radius: 0px;
  border-bottom-left-radius: 0px;
  text-transform: capitalize;
}

/* line 3389, ../sass/app.scss */
.calendar-2 {
  margin-bottom: 50px;
}

/* line 3395, ../sass/app.scss */
.calendar-style-2 {
  width: 100%;
  min-height: 500px;
  position: relative;
  background: #cebe29;
  background: -moz-linear-gradient(-45deg, #cebe29 0%, #9b1f50 33%, #2989d8 71%, #89b4ff 91%);
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #cebe29), color-stop(33%, #9b1f50), color-stop(71%, #2989d8), color-stop(91%, #89b4ff));
  background: -webkit-linear-gradient(-45deg, #cebe29 0%, #9b1f50 33%, #2989d8 71%, #89b4ff 91%);
  background: -o-linear-gradient(-45deg, #cebe29 0%, #9b1f50 33%, #2989d8 71%, #89b4ff 91%);
  background: -ms-linear-gradient(-45deg, #cebe29 0%, #9b1f50 33%, #2989d8 71%, #89b4ff 91%);
  background: linear-gradient(135deg, #cebe29 0%, #9b1f50 33%, #2989d8 71%, #89b4ff 91%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cebe29', endColorstr='#89b4ff',GradientType=1 );
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
}

/*ecommerce dashboard*/
/* line 3414, ../sass/app.scss */
.e-widgets {
  margin: 30px 0px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  background-color: #FFF;
  width: 100%;
}

/* line 3422, ../sass/app.scss */
.e-title {
  padding: 18px 25px;
  text-align: center;
  color: #FFF;
  display: inline-block;
}
/* line 3427, ../sass/app.scss */
.e-title i {
  font-size: 54px;
}

/* line 3431, ../sass/app.scss */
.e-widget-text {
  padding: 0px 10px;
  display: inline-block;
}
/* line 3435, ../sass/app.scss */
.e-widget-text h3 {
  font-weight: 600;
}
/* line 3438, ../sass/app.scss */
.e-widget-text p {
  font-style: italic;
  font-size: 14px;
  color: #aaaaaa;
}

/* line 3450, ../sass/app.scss */
.e-members .member-list ul li a .user-info {
  width: 100%;
}

/*products page*/
/* line 3461, ../sass/app.scss */
.e-product-table {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: #FFF;
  border: #d7d7d7 solid 1px;
  padding: 0px 10px;
  margin: 30px 0px;
}
/* line 3468, ../sass/app.scss */
.e-product-table h3 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
}

/* line 3478, ../sass/app.scss */
.product-list thead tr th {
  font-weight: 600;
  border: none;
  font-size: 14px;
}
/* line 3486, ../sass/app.scss */
.product-list td {
  font-size: 12.5px;
  font-weight: 600;
  vertical-align: middle !important;
}
/* line 3491, ../sass/app.scss */
.product-list td h4 {
  margin: 0px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
  color: #7ed5fb;
}
/* line 3498, ../sass/app.scss */
.product-list td button {
  margin: 0px;
}

/* line 3505, ../sass/app.scss */
.order-panel {
  margin: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
/* line 3508, ../sass/app.scss */
.order-panel .panel-heading {
  background-color: #76c80e;
  color: #FFF;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
/* line 3513, ../sass/app.scss */
.order-panel .panel-body {
  padding-bottom: 0px;
}

/* line 3518, ../sass/app.scss */
.message-box, .e-notifications {
  margin: 30px 0px;
}

/* line 3523, ../sass/app.scss */
footer {
  margin-top: 30px;
}

/* line 3526, ../sass/app.scss */
.e-visitor-stats {
  margin-top: 30px;
}

/* line 3532, ../sass/app.scss */
.order-list thead tr th {
  font-size: 16px;
  font-weight: 600;
  color: #777777;
  padding: 8px 0px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}
/* line 3539, ../sass/app.scss */
.order-list thead tr th.text-left {
  text-align: left;
}
/* line 3547, ../sass/app.scss */
.order-list tbody tr td {
  font-size: 14px;
  border-top: none;
  color: #777777;
  vertical-align: middle !important;
  font-weight: 600;
  padding: 8px 0px;
  text-align: center;
}
/* line 3555, ../sass/app.scss */
.order-list tbody tr td.text-left {
  text-align: left;
}

/* line 3562, ../sass/app.scss */
.product-of-month {
  margin: 30px 0px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  position: relative;
}
/* line 3566, ../sass/app.scss */
.product-of-month .e-product-price {
  position: absolute;
  top: -14px;
  left: 10px;
  text-align: center;
  width: 40px;
  color: #FFF;
  font-size: 14px;
  height: 40px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  padding-top: 13px;
}

/* line 3580, ../sass/app.scss */
.product-bottom {
  background-color: #FFF;
  padding: 10px 5px;
}
/* line 3583, ../sass/app.scss */
.product-bottom .e-pro-name {
  font-size: 13px;
  padding-top: 3px;
  font-weight: 600;
  color: #000;
}

/* line 3591, ../sass/app.scss */
.message-detail {
  display: table;
  width: 100%;
  margin-bottom: 10px;
}
/* line 3596, ../sass/app.scss */
.message-detail .sender-avatar {
  display: table-cell;
  vertical-align: top;
  padding-right: 20px;
}
/* line 3602, ../sass/app.scss */
.message-detail .time-stat {
  margin-top: 5px;
  margin-bottom: 20px;
}

/* line 3607, ../sass/app.scss */
.message-body {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  display: table-cell;
  vertical-align: top;
  padding: 10px 5px;
  font-size: 14px;
  line-height: 20px;
  position: relative;
}
/* line 3615, ../sass/app.scss */
.message-body i {
  position: absolute;
  z-index: 100;
  font-size: 22px;
}

/* line 3623, ../sass/app.scss */
.sender-detail .sender-avatar {
  padding-right: 20px;
}
/* line 3626, ../sass/app.scss */
.sender-detail .message-body {
  background-color: #35c4f9;
  color: #FFF;
}
/* line 3629, ../sass/app.scss */
.sender-detail .message-body i {
  left: -7px;
  top: 14px;
  color: #35c4f9;
}

/* line 3638, ../sass/app.scss */
.admin-message-detail .sender-avatar {
  padding-left: 20px;
}
/* line 3641, ../sass/app.scss */
.admin-message-detail .message-body {
  background-color: #e1e7f0;
  color: #515974;
}
/* line 3644, ../sass/app.scss */
.admin-message-detail .message-body i {
  right: -7px;
  top: 14px;
  color: #e1e7f0;
}

/*notifications*/
/* line 3655, ../sass/app.scss */
.e-notifications .panel-heading {
  background-color: #ec407a;
  color: #FFF;
}

/* line 3661, ../sass/app.scss */
.alert-notice {
  display: table;
  width: 100%;
  margin: 5px 0px 14px 0px;
}
/* line 3668, ../sass/app.scss */
.alert-notice .twitter {
  background-color: #35c4f9;
}
/* line 3670, ../sass/app.scss */
.alert-notice .twitter i {
  color: #35c4f9;
}
/* line 3674, ../sass/app.scss */
.alert-notice .facebook {
  background-color: #3d5b97;
}
/* line 3676, ../sass/app.scss */
.alert-notice .facebook i {
  color: #3d5b97;
}
/* line 3680, ../sass/app.scss */
.alert-notice .email-purple {
  background-color: #9c27b0;
}
/* line 3682, ../sass/app.scss */
.alert-notice .email-purple i {
  color: #9c27b0;
}
/* line 3686, ../sass/app.scss */
.alert-notice .shopping-cart {
  background-color: #ff9500;
}
/* line 3688, ../sass/app.scss */
.alert-notice .shopping-cart i {
  color: #ff9500;
}
/* line 3692, ../sass/app.scss */
.alert-notice .cloud {
  background-color: #ff5e3a;
}
/* line 3694, ../sass/app.scss */
.alert-notice .cloud i {
  color: #ff5e3a;
}

/* line 3700, ../sass/app.scss */
.notification-icon {
  display: table-cell;
  vertical-align: middle;
  color: #FFF;
  text-align: center;
  padding-right: 15px;
}
/* line 3706, ../sass/app.scss */
.notification-icon i {
  width: 40px;
  height: 40px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* line 3715, ../sass/app.scss */
.notification-detail {
  display: table-cell;
  vertical-align: middle;
  color: #FFF;
  position: relative;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 600;
  padding: 0px 10px;
}
/* line 3724, ../sass/app.scss */
.notification-detail i {
  position: absolute;
  font-size: 32px;
  left: -9px;
  top: 5px;
}

/* line 3731, ../sass/app.scss */
#googleMap {
  width: 100%;
  height: 280px;
  margin: 10px 0px;
}

/* line 3737, ../sass/app.scss */
.e-calendar {
  margin: 10px 0px;
}

/* line 3741, ../sass/app.scss */
.e-visitor-stats .panel-body {
  background-color: #00bcd4;
}

/*edit products*/
/* line 3937, ../sass/app.scss */
.edit-products {
  padding: 30px 0px;
}

/* line 3942, ../sass/app.scss */
.action-menu ul li {
  display: inline-block;
}
/* line 3944, ../sass/app.scss */
.action-menu ul li a {
  color: #FFF;
  padding: 3px 10px 4px 10px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  display: inline-block;
  font-size: 14px;
  text-decoration: none;
  outline: none;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
/* line 3953, ../sass/app.scss */
.action-menu ul li a:hover {
  opacity: 0.8;
}
/* line 3957, ../sass/app.scss */
.action-menu ul li ul {
  right: 0;
  left: auto;
  margin-top: 2px;
}
/* line 3961, ../sass/app.scss */
.action-menu ul li ul li {
  display: block;
}
/* line 3963, ../sass/app.scss */
.action-menu ul li ul li a {
  display: block;
  color: #000;
  padding: 6px 10px;
  font-size: 14px;
  font-weight: 600;
}

/*user profile*/
/* line 3978, ../sass/app.scss */
.user-profile {
  padding: 30px 0px;
}

/* line 3981, ../sass/app.scss */
.user-data {
  background-color: #FFF;
}
/* line 3984, ../sass/app.scss */
.user-data .card {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  border: none;
}
/* line 3988, ../sass/app.scss */
.user-data .card .card-block h4 {
  font-size: 18px;
  font-weight: 700;
  color: #525252;
}
/* line 3993, ../sass/app.scss */
.user-data .card .card-block p {
  font-size: 14px;
  line-height: 22px;
  color: #737373;
}

/* line 4002, ../sass/app.scss */
.noRadius {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

/* line 4005, ../sass/app.scss */
.radius {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

/* line 4009, ../sass/app.scss */
.user-social-links ul {
  margin: 15px 0px;
}
/* line 4011, ../sass/app.scss */
.user-social-links ul li {
  display: inline-block;
}
/* line 4013, ../sass/app.scss */
.user-social-links ul li a {
  width: 34px;
  height: 34px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  text-align: center;
  display: inline-block;
  margin-right: 5px;
  border: transparent solid 1px;
  padding-top: 5px;
}
/* line 4025, ../sass/app.scss */
.user-social-links ul li a i {
  color: #FFF;
}

/* line 4032, ../sass/app.scss */
.no-border {
  border: none !important;
}

/* line 4036, ../sass/app.scss */
.user-contact-detail li {
  display: table;
  width: 100%;
  padding: 10px 5px;
  border-bottom: #eeeeee solid 1px;
}
/* line 4041, ../sass/app.scss */
.user-contact-detail li i, .user-contact-detail li span {
  display: table-cell;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 600;
  color: #737373;
}

/* line 4053, ../sass/app.scss */
.user-activities {
  background-color: #FFF;
  padding: 15px 10px;
}
/* line 4056, ../sass/app.scss */
.user-activities .h3 {
  margin-top: 0px;
  margin-bottom: 10px;
}

/* line 4063, ../sass/app.scss */
.status-box textarea {
  height: 70px;
  resize: none;
  border-bottom: none;
}
/* line 4067, ../sass/app.scss */
.status-box textarea:focus {
  border-color: #ccc;
  box-shadow: none;
}

/* line 4074, ../sass/app.scss */
.status-action {
  background-color: #f2f3f4;
  padding: 5px;
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  border-top: none;
}
/* line 4081, ../sass/app.scss */
.status-action .left-action-links {
  padding-top: 3px;
}
/* line 4084, ../sass/app.scss */
.status-action .left-action-links a {
  display: inline-block;
  margin: 0px 10px;
  color: #c1c2c3;
  font-size: 18px;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
/* line 4090, ../sass/app.scss */
.status-action .left-action-links a:hover {
  opacity: 0.8;
}
/* line 4097, ../sass/app.scss */
.status-action .right-action-link a {
  display: inline-block;
  padding: 5px 15px;
  background-color: #1b93e1;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  text-decoration: none;
  outline: none;
  color: #FFF;
  font-size: 14px;
  text-align: center;
}
/* line 4108, ../sass/app.scss */
.status-action .right-action-link a:hover {
  opacity: 0.8;
}

/* line 4116, ../sass/app.scss */
.activity-tabs {
  margin: 30px 0px;
}

/* line 4121, ../sass/app.scss */
#stream .media {
  padding: 0 15px;
  border-bottom: #ccc solid 1px;
}
/* line 4124, ../sass/app.scss */
#stream .media h2 {
  font-size: 15px;
  color: #969696;
  margin-top: 0px;
}
/* line 4128, ../sass/app.scss */
#stream .media h2 strong {
  font-size: 16px;
  font-weight: 600;
  color: #1b93e1;
}
/* line 4134, ../sass/app.scss */
#stream .media .status-text {
  margin-top: 30px;
  font-size: 15px;
  line-height: 23px;
}

/* line 4141, ../sass/app.scss */
.user-photos {
  margin-bottom: 30px;
}

/* line 4144, ../sass/app.scss */
.user-friends {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  padding: 20px 10px;
  margin-bottom: 30px;
}

/*sign in page*/
/* line 4238, ../sass/app.scss */
.user-access {
  padding: 30px 0px;
}

/* line 4241, ../sass/app.scss */
.user-access-header {
  padding: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #FFF;
}

/* line 4252, ../sass/app.scss */
.login-widget {
  margin: 30px 0px;
}

/* line 4255, ../sass/app.scss */
.login-box {
  background-color: #FFF;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  border: #b2b2b2 solid 1px;
  border-bottom: none;
  text-align: center;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}
/* line 4262, ../sass/app.scss */
.login-box h3 {
  color: #FFF;
  font-size: 18px;
  font-weight: 600;
  padding: 20px 0px;
  margin: 0px;
}
/* line 4269, ../sass/app.scss */
.login-box form {
  padding: 20px 30px;
}
/* line 4271, ../sass/app.scss */
.login-box form input {
  min-height: 40px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

/* line 4278, ../sass/app.scss */
.form-actions {
  margin-top: 30px;
}
/* line 4280, ../sass/app.scss */
.form-actions .remember-me {
  float: left;
  margin-top: 8px;
}
/* line 4283, ../sass/app.scss */
.form-actions .remember-me input {
  display: inline-block;
  float: left;
  min-height: auto;
  margin: 3px 15px 0px 0px;
}
/* line 4289, ../sass/app.scss */
.form-actions .remember-me span {
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
}

/* line 4296, ../sass/app.scss */
.submit-btn {
  padding: 11px 30px;
  font-size: 16px;
  color: #FFF;
  background-color: #1b93e1;
  border: none;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
/* line 4305, ../sass/app.scss */
.submit-btn:hover {
  background-color: #1c80be;
}

/* line 4310, ../sass/app.scss */
.login-footer {
  border-top: #b2b2b2 solid 1px;
  border-bottom: #b2b2b2 solid 1px;
  padding: 22px 0px;
  text-align: center;
  background-color: #f7f7f7;
}
/* line 4316, ../sass/app.scss */
.login-footer a {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  display: block;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  text-decoration: none;
  outline: none;
}
/* line 4324, ../sass/app.scss */
.login-footer a:hover {
  color: #1c80be;
  text-decoration: none;
}

/* line 4331, ../sass/app.scss */
.register {
  margin: 30px 0px;
  text-align: center;
}
/* line 4334, ../sass/app.scss */
.register p {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
/* line 4338, ../sass/app.scss */
.register p a {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  outline: none;
  text-decoration: none;
}
/* line 4345, ../sass/app.scss */
.register p a:hover {
  color: #1c80be;
}

/* line 4352, ../sass/app.scss */
.login-success {
  border-top: #b2b2b2 solid 1px;
  border-bottom: #b2b2b2 solid 1px;
  padding: 30px 0px;
  text-align: center;
  background: #f7f7f7;
  position: relative;
}
/* line 4359, ../sass/app.scss */
.login-success p {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin-top: 30px;
}
/* line 4365, ../sass/app.scss */
.login-success a {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #c0c0c0;
}
/* line 4370, ../sass/app.scss */
.login-success a:hover {
  color: #000;
}

/* line 4377, ../sass/app.scss */
.forget-pass {
  border-top: #b2b2b2 solid 1px;
  border-bottom: #b2b2b2 solid 1px;
  padding: 30px 0px;
  text-align: center;
  background-color: #f7f7f7;
  position: relative;
}
/* line 4384, ../sass/app.scss */
.forget-pass p {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin-top: 10px;
}
/* line 4390, ../sass/app.scss */
.forget-pass a {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #c0c0c0;
}
/* line 4396, ../sass/app.scss */
.forget-pass a:hover {
  color: #000;
}

/* line 4402, ../sass/app.scss */
.reset-pass-btn {
  background: linear-gradient(#fcfcfc, #f0f0f0);
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  padding: 15px;
  border: #bcbcbc solid 1px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
/* line 4412, ../sass/app.scss */
.reset-pass-btn:hover {
  background: #1c80be;
  color: #FFF;
}

/* line 4419, ../sass/app.scss */
.user-access-page {
  background-color: #FFF;
  padding: 30px 15px;
  margin-top: 30px;
}

/* line 4425, ../sass/app.scss */
.login-style2 {
  padding: 40px 0px;
}
/* line 4428, ../sass/app.scss */
.login-style2 label {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
}

/* line 4451, ../sass/app.scss */
.lock-screen .login-box {
  border: none;
  min-width: 385px;
}
/* line 4455, ../sass/app.scss */
.lock-screen .lock-user-image {
  margin-top: 30px;
}
/* line 4458, ../sass/app.scss */
.lock-screen .form-actions {
  margin-top: 0px;
}

/* line 4479, ../sass/app.scss */
.page-header-content .header-text {
  color: #f2f2f2;
  text-align: center;
  padding: 50px 0px;
}
/* line 4483, ../sass/app.scss */
.page-header-content .header-text h1 {
  font-size: 48px;
  font-weight: 700;
}
/* line 4487, ../sass/app.scss */
.page-header-content .header-text p {
  font-size: 16px;
  font-weight: 600;
  color: #d2d2d2;
  letter-spacing: 1px;
  margin-bottom: 30px;
}

/* line 4499, ../sass/app.scss */
.page-text {
  margin-bottom: 30px;
}

/* line 4503, ../sass/app.scss */
.about-text {
  padding-top: 20px;
}
/* line 4505, ../sass/app.scss */
.about-text p {
  font-size: 18px;
  line-height: 30px;
}

/* line 4510, ../sass/app.scss */
.our-work {
  margin: 30px 0px;
}
/* line 4512, ../sass/app.scss */
.our-work h2 {
  font-size: 28px;
  font-weight: 700;
  color: #333;
}
/* line 4517, ../sass/app.scss */
.our-work img {
  margin-top: 30px;
}

/*contact age*/
/* line 4525, ../sass/app.scss */
.contact-page {
  padding: 30px 0px;
}
/* line 4527, ../sass/app.scss */
.contact-page .contact-map {
  margin-bottom: 30px;
}

/* line 4533, ../sass/app.scss */
.contact-form form, .comment-form form {
  margin-top: 30px;
}
/* line 4535, ../sass/app.scss */
.contact-form form label, .comment-form form label {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
/* line 4540, ../sass/app.scss */
.contact-form form .input-group, .comment-form form .input-group {
  margin-bottom: 30px;
}
/* line 4543, ../sass/app.scss */
.contact-form form textarea, .comment-form form textarea {
  resize: none;
  height: 150px;
}

/* line 4549, ../sass/app.scss */
.add-contact-info {
  padding: 30px 10px;
  color: #FFF;
  margin: 30px 0px;
}
/* line 4553, ../sass/app.scss */
.add-contact-info h3 {
  font-weight: 600;
  margin-bottom: 20px;
}
/* line 4557, ../sass/app.scss */
.add-contact-info button {
  margin-top: 30px;
}
/* line 4562, ../sass/app.scss */
.add-contact-info ul li {
  padding: 10px 0px;
}
/* line 4564, ../sass/app.scss */
.add-contact-info ul li i {
  margin-right: 20px;
}

/*page 404*/
/* line 4854, ../sass/app.scss */
.error-page {
  padding: 30px 0px;
}

/* line 4857, ../sass/app.scss */
.error-type {
  padding: 100px 10px;
  text-align: center;
}
/* line 4860, ../sass/app.scss */
.error-type span {
  font-size: 150px;
  font-weight: 700;
  display: inline-block;
}
/* line 4866, ../sass/app.scss */
.error-type i {
  font-size: 150px;
  display: inline-block;
}
/* line 4870, ../sass/app.scss */
.error-type p {
  font-size: 18px;
  font-weight: 600;
  color: #666;
  display: block;
  margin-top: 30px;
}

/*gallery page*/
/* line 4880, ../sass/app.scss */
.gallery-page {
  padding: 30px 0px;
}

/* line 4884, ../sass/app.scss */
.gallery-box {
  margin-bottom: 30px;
}

/*coming soon page*/
/* line 4890, ../sass/app.scss */
.coming-soon {
  padding: 30px 0px;
  background: #1f2036;
  min-height: 100vh;
  position: relative;
}

/* line 4899, ../sass/app.scss */
.cs-text {
  margin-top: 50px;
}
/* line 4901, ../sass/app.scss */
.cs-text h1 {
  color: #FFF;
  font-size: 60px;
  font-weight: 700;
  line-height: 92px;
}

/* line 4910, ../sass/app.scss */
.cs-footer {
  background-color: #131327;
  padding: 10px 0px;
  position: absolute;
  bottom: 0;
  width: 100%;
}
/* line 4917, ../sass/app.scss */
.cs-footer .get-email h3 {
  color: #FFF;
  font-size: 24px;
  display: inline-block;
}
/* line 4922, ../sass/app.scss */
.cs-footer .get-email form {
  display: inline-block;
  padding: 0px 20px;
}
/* line 4925, ../sass/app.scss */
.cs-footer .get-email form input {
  width: 289px;
  padding: 10px;
  border: none;
  color: #333;
  font-size: 16px;
}

/* line 4936, ../sass/app.scss */
.email-btn {
  background: #d63e4b;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  border: none;
  padding: 10px;
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
  margin-left: -4.1px;
  padding: 13px 15px;
  outline: none;
}
/* line 4947, ../sass/app.scss */
.email-btn:hover {
  background: #BB3C49;
}

/*search page*/
/* line 4954, ../sass/app.scss */
.search-page {
  padding: 30px 0px;
}

/* line 4960, ../sass/app.scss */
.search-results {
  background-color: #FFF;
  padding: 30px 15px;
}

/* line 4965, ../sass/app.scss */
.total-results {
  font-size: 15px;
  color: #666;
  padding-bottom: 15px;
  border-bottom: #eee solid 1px;
}

/* line 4971, ../sass/app.scss */
.link-unstyled, .result-box .search-link a {
  outline: none;
  text-decoration: none;
}

/* line 4975, ../sass/app.scss */
.result-box {
  margin-bottom: 20px;
  width: 100%;
  display: block;
}
/* line 4980, ../sass/app.scss */
.result-box .result-title a {
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  margin: 10px 0px 5px 0px;
  color: #00a0da;
  -moz-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  outline: none;
  text-decoration: none;
  text-transform: capitalize;
}
/* line 4990, ../sass/app.scss */
.result-box .result-title a:hover {
  color: #008ec5;
}
/* line 4996, ../sass/app.scss */
.result-box .search-link {
  display: block;
}
/* line 5000, ../sass/app.scss */
.result-box .search-link a {
  display: inline-block;
  color: #008c29;
  font-size: 14px;
}
/* line 5008, ../sass/app.scss */
.result-box .result-description {
  margin-top: 5px;
}
/* line 5010, ../sass/app.scss */
.result-box .result-description p {
  font-size: 15px;
  line-height: 22px;
  color: #666;
}

/*basic forms*/
/* line 5019, ../sass/app.scss */
.basic-form {
  padding: 10px 0px;
}
/* line 5021, ../sass/app.scss */
.basic-form label {
  text-align: right;
  width: 100%;
  line-height: 30px;
}

/* line 5027, ../sass/app.scss */
label {
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
  color: #333;
  margin-bottom: 5px;
}

/* line 5035, ../sass/app.scss */
.help-block {
  font-size: 12px;
}

/* line 5038, ../sass/app.scss */
input[type=checkbox], input[type=radio] {
  margin: 0px;
}

/* line 5043, ../sass/app.scss */
.radio-des {
  color: #737373;
  font-size: 12px;
}

/* line 5047, ../sass/app.scss */
.input-file {
  text-align: center !important;
  width: auto !important;
}

/* line 5053, ../sass/app.scss */
.has-danger .form-control-danger {
  border-color: #d9534f;
}
/* line 5056, ../sass/app.scss */
.has-danger .help-block {
  color: #d9534f;
}

/*advance forms*/
/* line 5062, ../sass/app.scss */
.advance-form {
  padding: 30px 0px;
}
/* line 5064, ../sass/app.scss */
.advance-form label {
  width: 100%;
  line-height: 28px;
}
/* line 5070, ../sass/app.scss */
.advance-form select option {
  font-size: 15px;
  color: #666;
}
/* line 5077, ../sass/app.scss */
.advance-form .selection {
  font-size: 15px;
  color: #666;
}

/* line 5083, ../sass/app.scss */
strong {
  font-weight: 700;
}

/* line 5086, ../sass/app.scss */
.jqx-action-button {
  right: 0 !important;
  left: auto !important;
  text-align: center;
  padding: 0px 10px;
  width: auto !important;
}

/* line 5094, ../sass/app.scss */
.jqx-icon-calendar {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
/* line 5101, ../sass/app.scss */
.jqx-icon-calendar:before {
  content: "\f073";
}

/*Form layout page*/
/* line 5108, ../sass/app.scss */
.form-layout {
  padding: 30px 0px;
}

/*editor page*/
/* line 5113, ../sass/app.scss */
.form-editor {
  padding: 30px 0px;
}

/* line 5116, ../sass/app.scss */
.jqx-editor {
  width: 100% !important;
}

/*validation page*/
/* line 5121, ../sass/app.scss */
.validation-page {
  padding: 30px 0px;
}

/* line 5124, ../sass/app.scss */
#errors {
  color: #a94442;
}

/*file upload*/
/* line 5131, ../sass/app.scss */
.file-upload {
  padding: 30px 0px;
}

/* line 5134, ../sass/app.scss */
.drag-forms {
  margin: 30px 0px;
}

/*charts js*/
/* line 5141, ../sass/app.scss */
.chart-js {
  padding: 30px 0px;
}

/* line 5144, ../sass/app.scss */
.chart-container {
  width: 100%;
}
/* line 5146, ../sass/app.scss */
.chart-container canvas {
  max-height: 320px;
}

/* line 5150, ../sass/app.scss */
.chart-panel {
  margin-bottom: 30px;
}

/* line 5154, ../sass/app.scss */
.morris-charts {
  padding: 30px 0px;
}

/* line 5157, ../sass/app.scss */
.gcharts {
  padding: 30px 0px;
}

/* line 5160, ../sass/app.scss */
.g-chart {
  width: 100%;
  height: 300px;
  overflow: hidden;
}

/* line 5166, ../sass/app.scss */
.basic-table {
  padding: 30px 0px;
}

/* line 5169, ../sass/app.scss */
.table-panel {
  margin-bottom: 30px;
}

/* line 5172, ../sass/app.scss */
.table {
  color: #666;
  font-size: 14px;
  margin-bottom: 0;
}

/* line 5177, ../sass/app.scss */
.table > thead > tr > th {
  border-bottom: none;
  font-weight: 700;
}

/* line 5181, ../sass/app.scss */
.thead-inverse th {
  color: #fff;
  background-color: #373a3c;
}

/* line 5185, ../sass/app.scss */
.table-inverse {
  color: #eceeef;
  background-color: #373a3c;
}

/* line 5190, ../sass/app.scss */
.table-active, .table-active > td, .table-active > th {
  background-color: rgba(0, 0, 0, 0.075);
}

/* line 5193, ../sass/app.scss */
.table-success, .table-success > td, .table-success > th {
  background-color: #dff0d8;
}

/* line 5196, ../sass/app.scss */
.table-info, .table-info > td, .table-info > th {
  background-color: #d9edf7;
}

/* line 5199, ../sass/app.scss */
.table-warning, .table-warning > td, .table-warning > th {
  background-color: #fcf8e3;
}

/* line 5202, ../sass/app.scss */
.table-danger, .table-danger > td, .table-danger > th {
  background-color: #f2dede;
}

/* line 5205, ../sass/app.scss */
.bg-primary {
  color: #fff !important;
  background-color: #0275d8 !important;
}

/* line 5209, ../sass/app.scss */
.bg-success {
  color: #fff !important;
  background-color: #5cb85c !important;
}

/* line 5213, ../sass/app.scss */
.bg-info {
  color: #fff !important;
  background-color: #5bc0de !important;
}

/* line 5217, ../sass/app.scss */
.bg-warning {
  color: #fff !important;
  background-color: #f0ad4e !important;
}

/* line 5221, ../sass/app.scss */
.bg-danger {
  color: #fff !important;
  background-color: #d9534f !important;
}

/* line 5225, ../sass/app.scss */
.advance-table {
  padding: 30px 0px;
}

/*data table page*/
/* line 5232, ../sass/app.scss */
.dataTables_length {
  margin-bottom: 10px;
  float: left;
}
/* line 5235, ../sass/app.scss */
.dataTables_length select {
  padding: 3px 10px;
  border-radius: 3px;
  border: #ddd solid 1px;
}

/* line 5241, ../sass/app.scss */
.dataTables_filter {
  margin-bottom: 10px;
}
/* line 5243, ../sass/app.scss */
.dataTables_filter input {
  padding: 3px 5px;
  border-radius: 3px;
  border: #ddd solid 1px;
}

/* line 5249, ../sass/app.scss */
table.dataTable.no-footer {
  border-bottom: none !important;
}

/* line 5252, ../sass/app.scss */
table.dataTable {
  border-collapse: collapse !important;
}

/* line 5255, ../sass/app.scss */
.dataTables_info {
  font-size: 14px;
  color: #666;
}

/* line 5260, ../sass/app.scss */
.dataTables_info {
  float: left;
}

/* line 5263, ../sass/app.scss */
.dataTables_paginate {
  float: right;
  margin-top: 6px !important;
}
/* line 5266, ../sass/app.scss */
.dataTables_paginate a {
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 6px 12px;
  font-size: 14px;
  cursor: pointer;
  border-right: none;
  outline: none;
}
/* line 5277, ../sass/app.scss */
.dataTables_paginate a:last-child {
  border-right: 1px solid #ddd;
  border-left: none;
}
/* line 5283, ../sass/app.scss */
.dataTables_paginate span a:last-child {
  border-left: 1px solid #ddd;
}

/* line 5291, ../sass/app.scss */
.table > tfoot > tr > th {
  border-bottom: none;
  font-weight: 700;
}

/* line 5295, ../sass/app.scss */
.responsive-table {
  padding: 30px 0px;
}

/*google map*/
/* line 5301, ../sass/app.scss */
.google-map {
  padding: 30px 0px;
}

/* line 5304, ../sass/app.scss */
.map-panel {
  margin-bottom: 30px;
}

/*snazzy maps*/
/* line 5310, ../sass/app.scss */
.snazzy-maps {
  padding: 30px 0px;
}

/* line 5313, ../sass/app.scss */
.snazmap {
  width: 100%;
  height: 300px;
}

/*contact page*/
/* line 5320, ../sass/app.scss */
.contact-page {
  padding: 30px 0px;
}

/* line 5327, ../sass/app.scss */
.contact-list ul li {
  display: block;
}
/* line 5329, ../sass/app.scss */
.contact-list ul li a {
  float: left;
  width: auto !important;
}
/* line 5333, ../sass/app.scss */
.contact-list ul li a span {
  float: left;
  padding: 0px 40px;
}
/* line 5337, ../sass/app.scss */
.contact-list ul li a:hover {
  background-color: transparent !important;
}
/* line 5341, ../sass/app.scss */
.contact-list ul li input {
  float: left;
  margin: 21px 0px 0px 0px;
}
/* line 5347, ../sass/app.scss */
.contact-list .contact-name {
  width: auto !important;
}
/* line 5350, ../sass/app.scss */
.contact-list .contact-name img {
  display: inline-block;
  float: left;
  padding-right: 10px;
}
/* line 5355, ../sass/app.scss */
.contact-list .contact-name i {
  margin-top: 12px;
  display: inline-block;
}
/* line 5361, ../sass/app.scss */
.contact-list .contact-email {
  margin-top: 11px;
}

/* line 5369, ../sass/app.scss */
.contact-action {
  margin-top: 14px;
  padding: 0px 5px;
}
/* line 5373, ../sass/app.scss */
.contact-action span a {
  padding: 5px 10px !important;
}

/* line 5380, ../sass/app.scss */
.floating-button {
  position: fixed;
  z-index: 100;
  bottom: 60px;
  right: 75px;
}
/* line 5385, ../sass/app.scss */
.floating-button a {
  display: flex;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  color: #fff;
  font-size: 20px;
  text-decoration: none;
  outline: none;
}
/* line 5397, ../sass/app.scss */
.floating-button a:hover {
  opacity: 0.9;
}

/*documentations page*/
/* line 5407, ../sass/app.scss */
.documentations {
  padding: 30px 0px;
}
/* line 5410, ../sass/app.scss */
.documentations li {
  font-size: 15px;
  line-height: 28px;
  color: #333;
}
/* line 5417, ../sass/app.scss */
.documentations .tab-pane a {
  color: #1b93e1;
}

/* line 5424, ../sass/app.scss */
.file-structure {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  border: #eee solid 1px;
  padding: 20px;
}

/* line 5430, ../sass/app.scss */
.copyrights {
  background-color: #FFF;
  padding: 10px;
  color: #666;
  font-size: 15px;
  text-align: center;
}

/* line 5438, ../sass/app.scss */
.settings-page {
  padding: 30px 0px;
}

/*docuemntation*/
/* line 5449, ../sass/app.scss */
.doc-content {
  padding: 30px 0px;
}
/* line 5452, ../sass/app.scss */
.doc-content .doc-sidebar {
  background-color: #FFF;
  padding: 10px;
  border-radius: 2px;
}
/* line 5457, ../sass/app.scss */
.doc-content .doc-sidebar .navbar {
  border-radius: 0px;
}
/* line 5459, ../sass/app.scss */
.doc-content .doc-sidebar .navbar ul {
  width: 100%;
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}
/* line 5464, ../sass/app.scss */
.doc-content .doc-sidebar .navbar ul li {
  width: 100%;
}
/* line 5466, ../sass/app.scss */
.doc-content .doc-sidebar .navbar ul li a {
  color: #1B2431;
  font-size: 14px;
  padding: 8px 5px;
  display: block;
  text-decoration: none;
  outline: none;
}
/* line 5474, ../sass/app.scss */
.doc-content .doc-sidebar .navbar ul li a:hover {
  color: #0F9DEA;
  background-color: transparent;
}
/* line 5481, ../sass/app.scss */
.doc-content .doc-sidebar .navbar ul li.tag-header {
  font-size: 15px;
  font-weight: 600;
  margin: 7px 0px;
}
/* line 5487, ../sass/app.scss */
.doc-content .doc-sidebar .navbar ul li.active a {
  border-color: #1B93E1;
  color: #1B93E1;
}

/* line 5499, ../sass/app.scss */
.doc-sections {
  background-color: #FFF;
  border-radius: 2px;
  padding: 0px 15px;
}
/* line 5503, ../sass/app.scss */
.doc-sections .section-cont {
  padding-bottom: 30px;
  padding-top: 5px;
}
/* line 5506, ../sass/app.scss */
.doc-sections .section-cont h3 {
  font-size: 24px;
  font-weight: 600;
}
/* line 5510, ../sass/app.scss */
.doc-sections .section-cont h4 {
  font-size: 18px;
  font-weight: 600;
  color: #444;
}
/* line 5515, ../sass/app.scss */
.doc-sections .section-cont p {
  color: #1B2431;
  font-size: 14px;
  line-height: 24px;
}
/* line 5521, ../sass/app.scss */
.doc-sections .section-cont a {
  color: #1B93E1;
}

/* line 5528, ../sass/app.scss */
.code-structure {
  background-color: #272822;
  margin: 20px 0px;
  padding: 20px;
  color: #FFF;
  border-radius: 2px;
}
/* line 5535, ../sass/app.scss */
.code-structure p {
  margin: 5px 0px;
  font-size: 15px;
  color: #FFF !important;
}
/* line 5539, ../sass/app.scss */
.code-structure p span {
  margin-left: 15px;
}
/* line 5542, ../sass/app.scss */
.code-structure p code {
  padding: 2px 10px;
}
/* line 5550, ../sass/app.scss */
.code-structure code {
  font-size: 18px;
  line-height: 28px;
}

/* line 5560, ../sass/app.scss */
.doc-sections .white-color a {
  color: #FFF;
}

/* line 5565, ../sass/app.scss */
.credits-list {
  margin: 30px 0px;
}

/* line 5569, ../sass/app.scss */
.doc-code {
  font-size: 18px;
  line-height: 28px;
  color: #c7254e;
}

/* line 5577, ../sass/app.scss */
.dash-2-members ul li a {
  padding: 12px 10px;
}

/* line 5588, ../sass/app.scss */
.reset-btn {
  text-align: center;
  margin-top: 50px;
}
/* line 5591, ../sass/app.scss */
.reset-btn .btn-reset {
  background-color: #1b93e1;
  color: white;
  border-radius: 2px;
  transition: all .5s ease-in;
  padding: 8px 50px;
}
/* line 5598, ../sass/app.scss */
.reset-btn .btn-reset:hover {
  background-color: #333333;
  transition: all .5s ease-in;
}

/*setting btn*/
/* line 5612, ../sass/app.scss */
.setting-box {
  margin: 0;
  padding: 16px 16px 16px 16px !important;
  border-radius: 25px 0 0 25px;
  display: block;
  background-color: #1b93e1;
  color: #ffffff;
  font-size: 20px !important;
  cursor: pointer;
  transition: color .2s;
}

/* line 5624, ../sass/app.scss */
.setting-box:hover {
  color: #ffffff;
}

/* line 5627, ../sass/app.scss */
.setting-btn {
  position: absolute;
  margin-bottom: 0;
  display: block;
  left: -49px;
  top: 45%;
}

/* line 5635, ../sass/app.scss */
#setting-card {
  position: fixed;
  z-index: 4000;
  top: 0;
  right: 0;
  margin-bottom: 0;
  height: 100%;
  display: block;
  width: 250px;
  background-color: white;
  transition: linear all 0.4s;
}

/* line 5649, ../sass/app.scss */
#setting-card.active {
  right: -250px;
}

@media (max-width: 992px) {
  /* line 5655, ../sass/app.scss */
  #setting-card {
    width: 200px;
  }

  /* line 5659, ../sass/app.scss */
  #setting-card.active {
    right: -200px;
  }
}
/* line 5663, ../sass/app.scss */
.config-box {
  width: 100%;
  position: relative;
  display: block;
  float: left;
  padding: 15px 15px;
  transition: all .7s ease-out;
  border-radius: 2px;
}

/* line 5674, ../sass/app.scss */
.add-color0 {
  background-color: #1b93e1;
  color: white;
}

/* line 5678, ../sass/app.scss */
.add-color1 {
  background-color: #333333;
  color: white;
}

/* line 5682, ../sass/app.scss */
.add-color2 {
  background-color: #37474f;
  color: white;
}

/* line 5686, ../sass/app.scss */
.add-color3 {
  background-color: #4caf50;
  color: white;
}

/* line 5690, ../sass/app.scss */
.add-color5 {
  background-color: #734920;
  color: white;
}

/* line 5694, ../sass/app.scss */
.add-color6 {
  background-color: #008c7f;
  color: white;
}

/* line 5698, ../sass/app.scss */
.add-color7 {
  background-color: #3f51b5;
  color: white;
}

/* line 5701, ../sass/app.scss */
.add-color8 {
  background-color: #501D89;
  color: white;
}

/* line 5705, ../sass/app.scss */
.add-color10 {
  background-color: rgba(0, 0, 0, 0.5);
  color: white !important;
}

/* line 5712, ../sass/app.scss */
.icon-rotate {
  animation: spin 3s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
/* line 5717, ../sass/app.scss */
.name-bar {
  color: #444;
  font-size: 18px;
  display: block;
  margin-bottom: 15px;
  font-weight: 700;
  text-align: center;
}

/* line 5727, ../sass/app.scss */
.heading-set {
  color: #333333;
  font-size: 14px;
  margin: 10px 0 10px;
  text-transform: capitalize;
  position: relative;
  display: block;
  width: 100%;
  font-weight: 700;
  float: left;
}

/* line 5738, ../sass/app.scss */
.sel-color {
  display: block;
  padding-left: 0;
  width: 100%;
  float: left;
  text-align: center;
}
/* line 5744, ../sass/app.scss */
.sel-color li {
  display: inline-block;
  list-style: none;
}

/* line 5751, ../sass/app.scss */
.quality {
  float: left;
  margin-right: 5px !important;
}

/* line 5756, ../sass/app.scss */
.form-group .quality {
  float: left;
  margin-right: 5px;
}

/* line 5761, ../sass/app.scss */
.form-check-label {
  margin-left: 5px;
}

/* line 5766, ../sass/app.scss */
.btn span.glyphicon {
  opacity: 0;
}

/* line 5769, ../sass/app.scss */
.btn.active span.glyphicon {
  opacity: 1;
  color: #444444;
}

/* line 5774, ../sass/app.scss */
.l-size {
  font-size: 12px !important;
  padding: 8px 10px;
}

/* line 5778, ../sass/app.scss */
.btn-group .btn + .btn {
  margin-left: 0 !important;
}

/* line 5781, ../sass/app.scss */
.btn-group .btn + .btn:hover {
  margin-left: 0 !important;
}

/* line 5787, ../sass/app.scss */
.theme-ch {
  margin: 5px 0px 10px;
  position: relative;
  float: left;
  text-align: center;
  display: block;
  width: 100%;
}

/* line 5795, ../sass/app.scss */
.white-clr {
  background-color: #ffffff;
  color: white;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

/* line 5801, ../sass/app.scss */
.main-clr1 {
  background-color: rgba(0, 0, 0, 0.1);
}

/* line 5805, ../sass/app.scss */
.main-clr2 {
  background-color: #dddddd;
}

/* line 5808, ../sass/app.scss */
.lRed-clr {
  background-color: #ff4a43;
}

/* line 5812, ../sass/app.scss */
.lDef-clr {
  background-color: #edf0f5;
}

/* line 5816, ../sass/app.scss */
.layout-mode, .skin-clr, .nav-mode {
  width: 100%;
  float: left;
  position: relative;
  display: block;
}

/* line 5822, ../sass/app.scss */
.line-1 {
  width: 100%;
  margin: 0 !important;
}

/* line 5826, ../sass/app.scss */
.clr-hvr {
  display: inline-block;
  transition: border .1s;
}
/* line 5830, ../sass/app.scss */
.clr-hvr.active {
  border: none;
}

/* line 5836, ../sass/app.scss */
.nav-chang-color {
  display: inline-block;
  transition: border .1s;
}
/* line 5840, ../sass/app.scss */
.nav-chang-color.active {
  border: none;
}

/* line 5850, ../sass/app.scss */
.Def-sidebar {
  height: 30px;
  width: 30px;
  background-color: #333333;
  border-radius: 50%;
  border: 4px solid #ffffff;
}

/* line 5859, ../sass/app.scss */
.light-gray-sidebar {
  height: 30px;
  width: 30px;
  background-color: #9B9B9B;
  border-radius: 50%;
  border: 4px solid #ffffff;
}

/* line 5868, ../sass/app.scss */
.White-sidebar {
  height: 30px;
  width: 30px;
  background-color: #edf0f5;
  border-radius: 50%;
  border: 4px solid #ffffff;
}

/* line 5878, ../sass/app.scss */
.teal-sidebar {
  height: 30px;
  width: 30px;
  background-color: #008c7f;
  border-radius: 50%;
  border: 4px solid #ffffff;
}

/* line 5887, ../sass/app.scss */
.pink-sidebar {
  height: 30px;
  width: 30px;
  background-color: #8e44ad;
  border-radius: 50%;
  border: 4px solid #ffffff;
}

/* line 5895, ../sass/app.scss */
.mblue-sidebar {
  height: 30px;
  width: 30px;
  background-color: #465487;
  border-radius: 50%;
  border: 4px solid #ffffff;
}

/* line 5903, ../sass/app.scss */
.mblue-logo {
  height: 30px;
  width: 30px;
  background-color: #465487;
  border-radius: 50%;
  border: 4px solid #ffffff;
}

/* line 5911, ../sass/app.scss */
.darkenred-sidebar {
  height: 30px;
  width: 30px;
  background-color: #ac5353;
  border-radius: 50%;
  border: 4px solid #ffffff;
}

/* line 5919, ../sass/app.scss */
.lb-clr {
  height: 30px;
  width: 30px;
  background-color: #444444;
  border-radius: 50%;
  border: 4px solid #ffffff;
}

/* line 5927, ../sass/app.scss */
.bleachedcedar-sidebar {
  height: 30px;
  width: 30px;
  background-color: #37474f;
  border-radius: 50%;
  border: 4px solid #ffffff;
}

/* line 5937, ../sass/app.scss */
.bluegray-sidebar {
  height: 30px;
  width: 30px;
  background-color: #607d8b;
  border-radius: 50%;
  border: 4px solid #ffffff;
}

/* line 5945, ../sass/app.scss */
.lbrown-sidebar {
  height: 30px;
  width: 30px;
  background-color: #7b3333;
  border-radius: 50%;
  border: 4px solid #ffffff;
}

/* line 5953, ../sass/app.scss */
.dark-grayish-logo {
  height: 30px;
  width: 30px;
  background-color: #773d4a;
  border-radius: 50%;
  border: 4px solid #ffffff;
}

/* line 5961, ../sass/app.scss */
.light-gray-logo {
  height: 30px;
  width: 30px;
  background-color: #9B9B9B;
  border-radius: 50%;
  border: 4px solid #ffffff;
}

/* line 5970, ../sass/app.scss */
.light-gray-logo {
  height: 30px;
  width: 30px;
  background-color: #9B9B9B;
  border-radius: 50%;
  border: 4px solid #ffffff;
}

/* line 5981, ../sass/app.scss */
.Def-logo {
  height: 30px;
  width: 30px;
  background-color: #1b93e1;
  border-radius: 50%;
  border: 4px solid #ffffff;
}

/* line 5989, ../sass/app.scss */
.lblack-logo {
  height: 30px;
  width: 30px;
  background-color: #333333;
  border-radius: 50%;
  border: 4px solid #ffffff;
}

/* line 5997, ../sass/app.scss */
.green-logo {
  height: 30px;
  width: 30px;
  background-color: #4caf50;
  border-radius: 50%;
  border: 4px solid #ffffff;
}

/* line 6007, ../sass/app.scss */
.bleachedcedar-logo {
  height: 30px;
  width: 30px;
  background-color: #37474f;
  border-radius: 50%;
  border: 4px solid #ffffff;
}

/* line 6016, ../sass/app.scss */
.lbrown-logo {
  height: 30px;
  width: 30px;
  background-color: #7b3333;
  border-radius: 50%;
  border: 4px solid #ffffff;
}

/* line 6025, ../sass/app.scss */
.add-Lblack {
  background-color: #444444;
}

@media (min-width: 1200px) {
  /* line 6031, ../sass/app.scss */
  .floating-cls {
    position: fixed;
    display: block;
    z-index: 3000;
    background: white;
  }
  /* line 6036, ../sass/app.scss */
  .floating-cls .top-widgets {
    margin-top: 0 !important;
  }
}
/* line 6047, ../sass/app.scss */
.boxed {
  position: relative !important;
  margin-left: 60px !important;
  margin-right: 60px !important;
}

/* line 6054, ../sass/app.scss */
.lGreen-scheme {
  background-color: #a2d200 !important;
}

/* line 6057, ../sass/app.scss */
.lPink-scheme {
  background-color: #8e44ad !important;
}

/* line 6062, ../sass/app.scss */
.Primary-scheme {
  background-color: #1b93e1 !important;
}

/* line 6066, ../sass/app.scss */
.Default-scheme {
  background-color: #edf0f5 !important;
}

/* line 6070, ../sass/app.scss */
.red-scheme {
  background-color: #ff4a43 !important;
}

/* line 6073, ../sass/app.scss */
.btn-01 {
  border-radius: 30px 0 0 30px;
  background-color: #a2d200;
  color: #ffffff;
  padding: 6px 15px;
  transition: all .3s;
  border-right: 1px solid #fff;
}
/* line 6081, ../sass/app.scss */
.btn-01:hover {
  background-color: rgba(0, 0, 0, 0.7);
  transition: all .3s;
  color: white;
}

/* line 6087, ../sass/app.scss */
.btn-02 {
  border-radius: 0 30px 30px 0;
  background-color: #ff9501;
  color: #ffffff;
  padding: 6px 15px;
  transition: all .3s;
}
/* line 6094, ../sass/app.scss */
.btn-02:hover {
  background-color: rgba(0, 0, 0, 0.7);
  transition: all .3s;
  color: white;
}

/* line 6101, ../sass/app.scss */
.change-float {
  background-color: rgba(0, 0, 0, 0.7);
}

@media (max-width: 1200px) {
  /* line 3, ../sass/screen.scss */
  .main-sidebar {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: auto;
    padding-bottom: 0px;
  }
  /* line 9, ../sass/screen.scss */
  .main-sidebar .logo {
    padding: 5px 20px;
    margin-bottom: 0px;
    border: 0px;
  }
  /* line 13, ../sass/screen.scss */
  .main-sidebar .logo a {
    float: left;
  }

  /* line 18, ../sass/screen.scss */
  .flight-widget .flight-content .flight-info .pull-left {
    border: none;
  }

  /* line 21, ../sass/screen.scss */
  .sidebar-menu {
    height: 0px;
    overflow: hidden;
    transition: opacity 0.5s ease-out;
    opacity: 0;
  }

  /* line 29, ../sass/screen.scss */
  .sidebar-menu.display-menu {
    height: auto;
    opacity: 1;
    border-top: #333 solid 1px;
  }

  /* line 34, ../sass/screen.scss */
  .main-content {
    width: 100%;
    margin-left: 0px;
  }

  /* line 38, ../sass/screen.scss */
  .info-box .info-box-head {
    left: 34%;
  }

  /* line 42, ../sass/screen.scss */
  .main-sidebar .logo a img {
    max-height: 40px;
  }
}
@media (max-width: 992px) {
  /* line 50, ../sass/screen.scss */
  .info-box .info-box-head {
    left: 40%;
  }

  /* line 53, ../sass/screen.scss */
  .search-top-widget input {
    padding: 5px 10px;
  }

  /* line 56, ../sass/screen.scss */
  #Dtable_wrapper {
    overflow-x: scroll;
  }

  /* line 59, ../sass/screen.scss */
  .dataTables_info {
    float: none;
    text-align: center;
    padding: 10px 0px;
  }

  /* line 64, ../sass/screen.scss */
  .dataTables_paginate {
    float: none;
    text-align: center !important;
    margin-bottom: 20px;
  }

  /* line 70, ../sass/screen.scss */
  #unseen table td:nth-child(2),
  #unseen table th:nth-child(2) {
    display: none;
  }

  /* line 72, ../sass/screen.scss */
  .map-widget {
    margin-bottom: 30px;
  }
}
@media (max-width: 768px) {
  /* line 80, ../sass/screen.scss */
  .blog-box .card {
    text-align: center;
  }
  /* line 82, ../sass/screen.scss */
  .blog-box .card img {
    display: inline-block;
  }

  /* line 88, ../sass/screen.scss */
  .e-visitor-stats {
    margin: 30px 0px;
  }

  /* line 91, ../sass/screen.scss */
  .navigation-style {
    text-align: center;
  }
  /* line 93, ../sass/screen.scss */
  .navigation-style .nav-logo {
    float: none !important;
    display: inline-block;
  }

  /* line 100, ../sass/screen.scss */
  .animation-box .media {
    text-align: center;
  }
  /* line 102, ../sass/screen.scss */
  .animation-box .media .media-left {
    display: block;
    text-align: center;
  }
  /* line 106, ../sass/screen.scss */
  .animation-box .media .media-right {
    display: block;
    text-align: center;
    width: 100%;
  }

  /* line 115, ../sass/screen.scss */
  .email-action-btn {
    width: 100%;
    text-align: center;
  }

  /* line 119, ../sass/screen.scss */
  .email-style-2 .email-action-list {
    padding: 2px 10px;
  }

  /* line 122, ../sass/screen.scss */
  .email-action-list .email-action-btn li {
    float: none;
    margin: 10px 10px;
  }

  /* line 126, ../sass/screen.scss */
  .compose-btn {
    margin-bottom: 8px;
  }

  /* line 129, ../sass/screen.scss */
  .info-box .info-box-head {
    display: none;
  }

  /* line 132, ../sass/screen.scss */
  .info-box {
    margin-top: 30px;
  }

  /* line 135, ../sass/screen.scss */
  .distance-graph {
    margin-bottom: 30px;
  }

  /* line 138, ../sass/screen.scss */
  .clients-widget {
    margin: 0px 0px;
  }

  /* line 141, ../sass/screen.scss */
  .calendar-widget {
    margin: 0px 0px;
  }

  /* line 144, ../sass/screen.scss */
  .admin-widgets {
    margin: 15px 0px;
  }

  /* line 147, ../sass/screen.scss */
  .social-links {
    margin-top: 30px;
  }

  /* line 150, ../sass/screen.scss */
  .advance-form label {
    text-align: left;
  }

  /* line 153, ../sass/screen.scss */
  .contact-action {
    display: none;
  }

  /* line 156, ../sass/screen.scss */
  .basic-form label {
    text-align: left;
  }

  /* line 159, ../sass/screen.scss */
  #unseen table td:nth-child(4),
  #unseen table th:nth-child(4),
  #unseen table td:nth-child(7),
  #unseen table th:nth-child(7),
  #unseen table td:nth-child(8),
  #unseen table th:nth-child(8) {
    display: none;
  }

  /* line 166, ../sass/screen.scss */
  .xs-m-0 {
    margin: 0px;
  }

  /* line 170, ../sass/screen.scss */
  .xs-m-30 {
    margin: 30px 0px;
  }

  /* line 173, ../sass/screen.scss */
  .admin-widgets .detail-list ul li.project-member {
    text-align: right;
  }
}
@media (max-width: 500px) {
  /* line 183, ../sass/screen.scss */
  .cs-footer {
    position: relative;
  }

  /* line 186, ../sass/screen.scss */
  .email-btn {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }

  /* line 194, ../sass/screen.scss */
  .get-email form input {
    margin: 10px 0px;
  }
}
/* line 6138, ../sass/app.scss */
.margin-top-none {
  margin-top: 0;
}

/* line 6140, ../sass/app.scss */
.margin-top-quarter {
  margin-top: 0.25em;
}

/* line 6142, ../sass/app.scss */
.margin-top-half {
  margin-top: 0.5em;
}

/* line 6144, ../sass/app.scss */
.margin-top-one {
  margin-top: 1em;
}

/* line 6146, ../sass/app.scss */
.margin-top-two {
  margin-top: 2em;
}

/* line 6150, ../sass/app.scss */
.padding-top-none {
  padding-top: 0;
}

/* line 6152, ../sass/app.scss */
.padding-top-quarter {
  padding-top: 0.25em;
}

/* line 6154, ../sass/app.scss */
.padding-top-half {
  padding-top: 0.5em;
}

/* line 6156, ../sass/app.scss */
.padding-top-one {
  padding-top: 1em;
}

/* line 6158, ../sass/app.scss */
.padding-top-two {
  padding-top: 2em;
}

/* line 6158, ../sass/app.scss */
.padding-logs {
  padding-top: 2.2em;
}

.padding-left-10 {
  padding-left: 25px;
}

.invoicePadding {
  padding: 0 30px 20px 30px;
}

.checkbox-text {
  padding-left: 35px;
  text-align: left !important;
}

/* react-autosuggest */
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 34px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #ffffe6;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--focused {
  background-color: #ddd;
}

#header {
  z-index: 200;
  height: 60px;
  position: absolute;
  width: 100%;
  margin-right: 0; }

#header .brand {
  float: left;
  width: 240px;
  height: 60px;
  padding: 0 0 0 10px;
  position: relative; }

#header .logo {
  font-size: 1.188em;
  text-transform: uppercase;
  padding: 17px 0 0 10px;
  display: inline-block; }

#header .logo img {
  margin-right: 6px;
  margin-top: -4px; }

#header .logo span {
  font-weight: 700; }

#header .navbar-nav {
  margin: 0; }

#header .navbar-left {
  float: left; }

#header .navbar-right {
  float: right; }

#header .btn i {
  padding: 0; }

#header button.sidebar-toggle,
#header button#toggle-profile,
#header button#toggle-fullscreen,
#header button.options {
  height: 60px;
  font-size: 1.125em;
  padding: 20px;
  cursor: pointer;
  float: left;
  background-color: transparent;
  border-radius: none;
  border: none; }

#header button.sidebar-toggle:hover, #header button.sidebar-toggle:active,
#header button#toggle-profile:hover, #header button#toggle-profile:active,
#header button#toggle-fullscreen:hover, #header button#toggle-fullscreen:active {
  box-shadow: none;
  outline: none !important;
  border: none; }

#header button.sidebar-toggle:focus, #header button#toggle-profile:focus, #header button#toggle-fullscreen:focus {
  outline: none;
  border: none; }

#header .search {
  -webkit-appearance: none !important;
  color: #282323;
  outline: 0;
  height: 30px;
  width: 280px;
  padding: 7px 15px;
  font-size: 0.75em;
  font-weight: normal;
  vertical-align: top;
  background-color: #EDF1F2;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  border-radius: 30px;
  border: none;
  transition: background 0.2s linear 0s, box-shadow 0.2s linear 0s;
  margin: 16px 0 0 20px;
  position: relative; }

#header .btn-search {
  position: absolute;
  top: 17px;
  right: 5px;
  background: none; }

#header .fa-search {
  font-size: 1.063em;
  color: #212121; }

#header .nav li.profile > a > .meta {
  display: table;
  width: 100%; }

#header .nav li.profile > a > .meta > .avatar {
  display: table-cell;
  vertical-align: middle;
  max-width: 32px;
  height: 32px; }

#header .nav li.profile > a > .meta > .text {
  font-size: 0.75em;
  font-weight: 600;
  padding: 0 5px; }

#header .nav li.profile > a {
  padding: 0 15px;
  height: 60px;
  line-height: 60px;
  color: #13161C;
  text-shadow: none; }

#header .nav li.profile > a:hover, #header .nav li.profile > a:active, #header .nav li.profile > a:focus {
  box-shadow: none;
  outline: none !important;
  border: none;
  color: #13161C; }

#header .navbar-right > .dropdown > .dropdown-menu {
  position: absolute;
  margin-top: 5px;
  left: 5px;
  background-color: #fff;
  border: 1px solid #e0e7e8;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08); }

#header .arrow {
  border-width: 8px;
  z-index: 10; }

#header .arrow, #header .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

#header .arrow:after {
  border-width: 7px;
  content: ""; }

#header .arrow.top {
  left: 50%;
  margin-left: -8px;
  border-top-width: 0;
  border-bottom-color: #eee;
  border-bottom-color: rgba(0, 0, 0, 0.1);
  top: -8px; }

#header .arrow.top:after {
  content: " ";
  top: 1px;
  margin-left: -7px;
  border-top-width: 0;
  border-bottom-color: #fff; }

#header .dropdown-menu > li > a > .icon {
  display: inline-block;
  min-width: 14px;
  text-align: center;
  margin-right: 5px; }

#header .dropdown-menu > li.active > a, #header .dropdown-menu > li:active > a {
  background-color: #fbfbfb;
  color: #444; }

#header .dropdown-menu > li.active > a:hover, #header .dropdown-menu > li:active > a:hover {
  background-color: #fbfbfb;
  color: #373737; }

#header .profile ul > li:first-child {
  padding: 10px; }

#header .profile ul > li h5, #header .profile ul > li p {
  font-size: 0.813em;
  margin: 0;
  padding: 0; }

#header .profile ul > li .progress {
  margin-bottom: 0; }

@media (max-width: 789px) {
  #header .search {
    width: 155px; } }

@media (max-width: 767px) {
  #header .brand {
    width: 100%;
    padding: 0 0 0 10px;
    text-align: center; }
  #header .logo {
    font-size: 1.375em;
    padding: 14px 0 0 0; }
  #header .logo img {
    width: 25px;
    margin-right: 5px; }
  #header .navbar-left {
    position: absolute;
    left: 0; }
  #header .navbar-right {
    position: absolute;
    right: 0; } }

@media only screen and (max-width: 660px) {
  #header .user-nav ul {
    padding-left: 0; }
  #header .toggle-navigation.toggle-left {
    float: left; }
  .user-nav ul li {
    font-size: 0.75em; } }

@media only screen and (max-width: 479px) {
  #header .dropdown.messages {
    display: none; } }

.sidebar {
  position: absolute;
  min-height: 100%;
  width: 240px;
  padding-top: 60px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out; }

.sidebar .nav a {
  text-decoration: none;
  font-weight: 600; }

.sidebar .nav i {
  font-size: 1em;
  margin-right: 5px; }

.sidebar .nav .nav-sub {
  list-style: none;
  padding: 0; }

.sidebar .nav .nav-sub li > a {
  padding-right: 10px;
  font-size: 0.813em;
  padding: 8px 0 8px 10px;
  display: block; }

.sidebar .nav > li > .nav-sub > li > a {
  padding-left: 45px; }

.sidebar .nav > li > .nav-sub > li > .nav-sub > li > a {
  padding-left: 55px; }

.sidebar .nav > li > .nav-sub > li > .nav-sub > li > .nav-sub > li > a {
  padding-left: 65px; }

.sidebar .nav > li > .nav-sub > li > .nav-sub > li > .nav-sub > li > .nav-sub > li > a {
  padding-left: 70px; }

.sidebar .nav > li > .nav-sub > li > .nav-sub > li > .nav-sub > li > .nav-sub > li > .nav-sub > li > a {
  padding-left: 75px; }

.sidebar-mini .sidebar .nav > li > .nav-sub > li > a {
  padding-left: 25px; }

.sidebar-mini .sidebar .nav > li > .nav-sub > li > .nav-sub > li > a {
  padding-left: 35px; }

.sidebar-mini .sidebar .nav > li > .nav-sub > li > .nav-sub > li > .nav-sub > li > a {
  padding-left: 45px; }

.sidebar-mini .sidebar .nav > li > .nav-sub > li > .nav-sub > li > .nav-sub > li > .nav-sub > li > a {
  padding-left: 55px; }

.sidebar .nav .nav-sub .nav-dropdown > a {
  padding-right: 30px; }

.sidebar .nav .nav-sub > .open > a, .sidebar .nav .nav-sub > .open > a:focus, .sidebar .nav .nav-sub > .open > a:hover {
  background-color: transparent;
  border-color: transparent; }

.sidebar .nav-pills {
  margin-left: 10px;
  margin-right: 10px; }

.sidebar .nav-pills > li > a {
  padding: 9px 10px;
  font-size: 0.875em; }

.sidebar-profile {
  padding: 20px; }

.sidebar-profile .profile-image {
  display: block;
  height: 60px;
  margin: auto; }

.sidebar-profile .avatar i {
  left: 70px; }

.sidebar-profile .profile-body {
  padding: 0;
  text-align: center; }

.sidebar-profile .profile-body h4 {
  color: #f5f5f5;
  margin: 10px 0 0 0;
  font-size: 12px;
  font-weight: 600; }

.sidebar-profile .profile-body .title {
  color: #8f939e;
  font-size: 12px; }

.sidebar-profile .profile-body .dropdown-menu .divider {
  height: 1px;
  margin: 3px 0;
  overflow: hidden;
  background-color: #e5e5e5; }

.sidebar-profile .profile-body.dropdown > .dropdown-menu {
  position: absolute;
  margin-top: 20px;
  left: 20px;
  background-color: #fff;
  border: 1px solid #e0e7e8;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  border-style: solid; }

.sidebar-profile .profile-body.dropdown > .dropdown-menu:before {
  bottom: 100%;
  left: 85%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 8px;
  margin-left: -16px;
  top: -16px; }

.sidebar-profile .profile-body.dropdown > .dropdown-menu li a, .sidebar-profile .profile-body.dropdown > .dropdown-menu li a:active {
  font-size: 12px; }

.sidebar-profile .profile-body.dropdown > .dropdown-menu .profile-progress {
  padding: 10px; }

.sidebar-profile .profile-body.dropdown > .dropdown-menu .progress {
  margin-bottom: 0; }

.sidebar-profile .profile-body.dropdown > .dropdown-menu h5 {
  margin: 0;
  padding: 0; }

.sidebar-profile .profile-body.dropdown > .dropdown-menu .icon {
  margin-right: 5px; }

.sidebar-header {
  color: #6F737E;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  padding: 10px 10px 5px;
  text-transform: uppercase; }

.sidebar-left a {
  color: #B3B8C3; }

.sidebar-left a:focus, .sidebar-left a:hover {
  color: #fff;
  background-color: transparent; }

.sidebar-left .active > a, .sidebar-left .active > a:focus, .sidebar-left .active > a:hover {
  color: #fff; }

.sidebar-left .disabled > a, .sidebar-left .disabled > a:focus, .sidebar-left .disabled > a:hover {
  color: #5c606b;
  text-decoration: none;
  background-color: transparent;
  cursor: not-allowed; }

.sidebar-left .sidebar-profile .media-body h4 {
  color: #f5f5f5; }

.sidebar-mini .sidebar-left .nav > li.open > a {
  color: #B3B8C3;
  background-color: transparent; }

.sidebar-left .nav > li > a:focus {
  color: #fff;
  background-color: transparent; }

.sidebar-left .nav > .disabled > a, .sidebar-left .nav > .disabled > a:focus, .sidebar-left .nav > .disabled > a:hover {
  color: #5c606b;
  background-color: transparent; }

.sidebar-left .nav-sub li.open > a {
  color: #fff;
  background-color: transparent; }

.sidebar + .main-content-wrapper {
  width: 100%; }

.sidebar-mini .sidebar .sidebar-profile {
  padding: 15px 0;
  margin: -20px 0 10px 0;
  display: none; }

.sidebar-mini .sidebar .sidebar-profile .profile-image {
  display: block;
  width: 30px;
  height: 30px;
  margin: auto; }

.sidebar-mini .sidebar .sidebar-profile .profile-body {
  padding: 0; }

.sidebar-mini .sidebar .sidebar-profile .profile-body h4, .sidebar-mini .sidebar .sidebar-profile .profile-body .title {
  display: none; }

.sidebar-mini .sidebar .sidebar-profile .profile-body.dropdown > .dropdown-menu {
  position: absolute;
  margin-top: 12px;
  left: 4px; }

.sidebar-mini .sidebar .sidebar-profile .profile-body.dropdown > .dropdown-menu:before {
  left: 18%; }

.sidebar-mini .sidebar .avatar i {
  position: absolute;
  left: 2px;
  top: 0; }

@media (max-width: 767px) {
  .sidebar {
    left: -240px; }
  .sidebar-opened .sidebar-left {
    display: block;
    left: 0; }
  .sidebar-opened .sidebar-left + .main-content-wrapper {
    left: 240px;
    overflow: hidden; }
  .sidebar-opened {
    overflow-x: hidden; } }

@media (min-width: 768px) {
  .sidebar {
    display: block; }
  .sidebar-mini .sidebar {
    width: 50px;
    padding-top: 80px;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out; }
  .sidebar-mini .sidebar .nav > li.nav-dropdown.nav-dropdown-open > a, .sidebar-mini .sidebar .nav > li.nav-dropdown:hover > a {
    border-bottom-right-radius: 0; }
  .sidebar-mini .sidebar .nav > li.nav-dropdown > a:after {
    display: none;
    content: "";
    top: 12px;
    left: 34px;
    font-size: 0.625em; }
  .sidebar-mini .sidebar .nav > li > a {
    padding: 10px 9px;
    white-space: nowrap;
    overflow: hidden; }
  .sidebar-mini .sidebar .nav > li a .badge, .sidebar-mini .sidebar .nav > li a .label {
    display: none; }
  .sidebar-mini .sidebar .nav > li > a > i:first-child {
    margin-right: 10px;
    width: 22.28px; }
  .sidebar-mini .sidebar .nav > li > .nav-sub {
    position: absolute;
    left: 50px;
    margin-top: 0;
    padding-bottom: 2px;
    display: none !important;
    border-radius: 0 0 4px; }
  .sidebar-mini .sidebar .nav > li.nav-dropdown-open, .sidebar-mini .sidebar .nav > li:hover {
    width: 240px;
    z-index: 100; }
  .sidebar-mini .sidebar .nav > li.nav-dropdown-open a, .sidebar-mini .sidebar .nav > li:hover a {
    display: block;
    overflow: visible;
    white-space: normal; }
  .sidebar-mini .sidebar .nav > li.nav-dropdown-open a .badge, .sidebar-mini .sidebar .nav > li.nav-dropdown-open a .label, .sidebar-mini .sidebar .nav > li:hover a .badge, .sidebar-mini .sidebar .nav > li:hover a .label {
    display: block; }
  .sidebar-mini .sidebar .nav > li.nav-dropdown-open > .nav-sub, .sidebar-mini .sidebar .nav > li:hover > .nav-sub {
    display: block !important;
    z-index: 10; }
  .sidebar-mini .sidebar .nav-pills {
    margin-left: 5px;
    margin-right: 5px; }
  .sidebar-mini .sidebar .nav-pills > li.nav-dropdown-open, .sidebar-mini .sidebar .nav-pills > li:hover {
    width: 235px; }
  .sidebar-mini .sidebar .nav-pills > li > a > i {
    margin-right: 10px;
    width: 23px; }
  .sidebar-mini .sidebar .nav-pills > li.nav-dropdown > a {
    padding-right: 10px; }
  .sidebar-mini .sidebar .nav-pills > li > .nav-sub {
    left: 45px;
    width: 190px; }
  .sidebar-mini .sidebar .nav-pills > li > .nav-sub a {
    padding-right: 5px; }
  .sidebar-mini .sidebar .nav-pills > li > .nav-sub .nav-dropdown > a {
    padding-right: 30px; }
  .sidebar-mini .sidebar .sidebar-header, .sidebar-mini .sidebar .sidebar-profile, .sidebar-mini .sidebar .sidebar-summary {
    display: none; }
  .sidebar + .main-content-wrapper {
    margin-left: 240px;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    width: auto; }
  .sidebar-mini .navbar .navbar-brand-group {
    width: 100px; }
  .sidebar-mini .navbar .navbar-brand-group .navbar-brand {
    width: 50px; }
  .sidebar-mini .sidebar + .main-content-wrapper {
    margin-left: 50px;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out; } }

@media only screen and (max-width: 660px) {
  .sidebar-toggle {
    margin-left: 0;
    width: 100%; }
  .main-content-toggle-left {
    margin-left: 660px; }
  .user-nav ul li {
    font-size: 0.75em; } }

.sidebar div.divider {
  margin: 10px 0;
  width: 100%;
  height: 1px;
  background: #13161C; }

.sidebar .nav-pills > li > a > .badge {
  margin: 3px 0; }

.sidebar .setting-list {
  padding: 0 20px 20px 20px; }

.sidebar .setting-list label {
  color: #B3B8C3;
  font-size: 0.75em;
  margin-bottom: 10px; }

.sidebar .setting-list label:hover {
  cursor: pointer; }

.sidebar-mini .setting-list {
  display: none; }

.main-content-wrapper {
  margin-right: 0;
  padding-top: 60px;
  background: #F0F3F3;
  position: relative; }

.main-content-wrapper #main-content {
  padding: 15px 15px 0 15px;
  width: 100%; }

.main-content-wrapper #main-content .h1 {
  margin: 0;
  padding: 0px 10px 40px 10px;
  float: left;
  line-height: 10px;
  font-weight: 300;
  font-size: 1.5em; }

.main-content-toggle-left {
  margin-left: 0; }

.main-content-toggle-right {
  margin-right: 240px; }

  .pageheader {
  padding: 15px;
  border-bottom: 1px solid #dce0e6;
  border-top: 1px solid #eee;
  background: #F6F8F8;
  position: relative; }

.pageheader h2 {
  font-size: 1em;
  margin: 0; }

@media (max-width: 767px) {
  .main-content-wrapper {
    margin-left: 0; } }

@media only screen and (max-width: 767px) and (min-width: 480px) {
  /* Main Content */
  #main-content .h1 {
    font-size: 1.5em; } }

@media only screen and (max-width: 660px) {
  .main-content-toggle-left {
    margin-left: 660px; } }

@media only screen and (max-width: 479px) {
  /* Main Content */
  #main-content .h1 {
    font-size: 22px; } }

.theme-blue #header {
  background: #fff; }

.theme-blue #header .brand {
  background: #1F7BB6; }

.theme-blue #header .brand .logo {
  color: #fff; }

.theme-blue #header button.sidebar-toggle, .theme-blue #header button#toggle-profile, .theme-blue #header button#toggle-fullscreen, .theme-blue #header .nav li.profile > a {
  color: #1d212a; }

.theme-blue #header button.sidebar-toggle:hover, .theme-blue #header button.sidebar-toggle:active, .theme-blue #header button#toggle-profile:hover, .theme-blue #header button#toggle-profile:active,
.theme-blue #header button#toggle-fullscreen:hover, .theme-blue #header button#toggle-fullscreen:active {
  background: #f6f8f8; }

.theme-blue #header .nav li.profile > a:hover, .theme-blue #header .nav li.profile > a:active, .theme-blue #header .nav li.profile > a:focus {
  background: #f6f8f8; }

.theme-blue .sidebar-left {
  background-color: #1d212a; }

.theme-blue .sidebar-mini .sidebar-left .nav > li.active > a:focus, .theme-blue .sidebar-mini .sidebar-left .nav > li.active > a:hover, .theme-blue .sidebar-left .nav > li.active > a, .theme-blue .sidebar-left .nav > li.active > a:focus, .theme-blue .sidebar-left .nav > li.active > a:hover {
  color: #fff;
  background-color: #1F7BB6; }

.theme-blue .sidebar-left .nav > li.open > a, .theme-blue .sidebar-left .nav > li > a:hover {
  color: #1d2939;
  background-color: #ffffff; }

.theme-blue.sidebar-mini .sidebar-left .nav > li.nav-dropdown-open > .nav-sub, .theme-blue.sidebar-mini .sidebar-left .nav > li:hover > .nav-sub {
  background-color: #1d212a; }

.theme-blue .sidebar-profile {
  background: #13161c; }

.theme-blue.sidebar-mini .sidebar-left .nav > li.active > a, .theme-blue.sidebar-mini .sidebar-left .nav > li.active > a:focus, .theme-blue.sidebar-mini .sidebar-left .nav > li.active > a:hover, .theme-blue .sidebar-left .nav > li.active > a, .theme-blue .sidebar-left .nav > li.active > a:focus, .theme-blue .sidebar-left .nav > li.active > a:hover {
  color: #fff;
  background-color: #1F7BB6; }

.theme-blue.sidebar-mini .sidebar-left .nav > li.nav-dropdown-open > a, .theme-blue.sidebar-mini .sidebar-left .nav > li:hover > a {
  color: #1d2939;
  background-color: #fff; }

.theme-blue-full #header {
  background: #1F7BB6; }

.theme-blue-full #header .brand {
  background: #1F7BB6; }

.theme-blue-full #header .brand .logo {
  color: #fff; }

.theme-blue-full #header button.sidebar-toggle, .theme-blue-full #header button#toggle-profile, .theme-blue-full #header button#toggle-fullscreen, .theme-blue-full #header .nav li.profile > a {
  color: #fff; }

.theme-blue-full #header button.sidebar-toggle:hover, .theme-blue-full #header button.sidebar-toggle:active, .theme-blue-full #header button#toggle-profile:hover, .theme-blue-full #header button#toggle-profile:active,
.theme-blue-full #header button#toggle-fullscreen:hover, .theme-blue-full #header button#toggle-fullscreen:active {
  background: #238acc; }

.theme-blue-full #header .nav li.profile > a:hover, .theme-blue-full #header .nav li.profile > a:active, .theme-blue-full #header .nav li.profile > a:focus {
  background: #238acc; }

.theme-blue-full .sidebar-left {
  background-color: #1d212a; }

.theme-blue-full .sidebar-mini .sidebar-left .nav > li.active > a:focus, .theme-blue-full .sidebar-mini .sidebar-left .nav > li.active > a:hover, .theme-blue-full .sidebar-left .nav > li.active > a, .theme-blue-full .sidebar-left .nav > li.active > a:focus, .theme-blue-full .sidebar-left .nav > li.active > a:hover {
  color: #fff;
  background-color: #1F7BB6; }

.theme-blue-full .sidebar-left .nav > li.open > a, .theme-blue-full .sidebar-left .nav > li > a:hover {
  color: #1d2939;
  background-color: #ffffff; }

.theme-blue-full.sidebar-mini .sidebar-left .nav > li.nav-dropdown-open > .nav-sub, .theme-blue-full.sidebar-mini .sidebar-left .nav > li:hover > .nav-sub {
  background-color: #1d212a; }

.theme-blue-full .sidebar-profile {
  background: #13161c; }

.theme-blue-full.sidebar-mini .sidebar-left .nav > li.active > a, .theme-blue-full.sidebar-mini .sidebar-left .nav > li.active > a:focus, .theme-blue-full.sidebar-mini .sidebar-left .nav > li.active > a:hover, .theme-blue-full .sidebar-left .nav > li.active > a, .theme-blue-full .sidebar-left .nav > li.active > a:focus, .theme-blue-full .sidebar-left .nav > li.active > a:hover {
  color: #fff;
  background-color: #1F7BB6; }

.theme-blue-full.sidebar-mini .sidebar-left .nav > li.nav-dropdown-open > a, .theme-blue-full.sidebar-mini .sidebar-left .nav > li:hover > a {
  color: #1d2939;
  background-color: #e6f2ff; }
 
 .form-padding {
  padding: 0 10px;
}

.form-padding-20 {
  padding: 0 20px;
}

.form-multiselect {
  padding-left: 4px;
  margin-bottom: 15px;
}

.fa-user-plus span {
  width: 18px;
  height: 18px;
  font-family: 'Open Sans', sans-serif;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  display: block;
  position: absolute;
  color: #FFF;
  top: 9px;
  left: 9px;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
}

.dropzone {
  width: 400px;
    border-width: 2px;
    border-color: #ccc;
    border-style: dashed;
    border-radius: 5px;
    font-size: 12px;
    padding: 10px;
}

.veiwCal {
  height: 500px;
}
.capitalize  {
  text-transform: capitalize;
}

.hr-line {
  margin: 5px 0 15px 0;
  border-top: 1px solid #eee;
  border-radius: 0px;
}

.help-link {
  display:inline-block;
  width:100%;
  height:100%;
  text-decoration: none;
}

.label-padding {
  margin-left: 3px;
}

.form-divider {
  border-right: #e3e3e3 solid 1px;
}

.invoice-box{
  max-width:800px;
  margin:auto;
  padding:30px;
  border:1px solid #eee;
  box-shadow:0 0 10px rgba(0, 0, 0, .15);
  font-size:16px;
  line-height:24px;
  font-family:'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  color:#555;
}

.invoice-box table{
  width:100%;
  line-height:inherit;
  text-align:left;
}

.invoice-box table td{
  padding:5px;
  vertical-align:top;
}

.invoice-box table tr td:nth-child(2){
  text-align:right;
}

.invoice-box table tr.top table td{
  padding-bottom:20px;
}

.invoice-box table tr.top table td.title{
  font-size:45px;
  line-height:45px;
  color:#333;
}

.invoice-box table tr.information table td{
  padding-bottom:40px;
}

.invoice-box table tr.heading td{
  background:#eee;
  border-bottom:1px solid #ddd;
  font-weight:bold;
}

.invoice-box table tr.details td{
  padding-bottom:20px;
}

.invoice-box table tr.item td{
  border-bottom:1px solid #eee;
}

.invoice-box table tr.item.last td{
  border-bottom:none;
}

.invoice-box table tr.total td:nth-child(2){
  border-top:2px solid #eee;
  font-weight:bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td{
      width:100%;
      display:block;
      text-align:center;
  }
  
  .invoice-box table tr.information table td{
      width:100%;
      display:block;
      text-align:center;
  }
}

.date-input {
  width: 90px;
}

.row-query>div>div{ background-color:#ffffee !important; }

.tutorBox {
  display: flex;
  align-items: center;
}

.tutorBox > div {
  width: 100%;
  margin-right: 2px;
}

.fullSize {
  width: 90%;
}

.dataModal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: -10px 0 5px;
}

.emailLog {
  display: flex;
  align-items: center;
}

.logHeading {
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
}

.emailStatus {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mr5 {
  margin-right: 5px;
}

.bulkUpdate {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}

.bulkUpdateFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 15px;
  border-top: #ccc 1px solid;
}

.w90 {
  width: 90px;
}

.w120 {
  width: 120px;
}

.w150 {
  width: 150px;
}

.w200 {
  width: 200px;
}

.w250 {
  width: 250px;
}

.w300 {
  width: 300px;
}

.fullWidth {
  flex: 1;
}

.d-flex {
  display: flex;
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.pr-1 {
  padding-right: 10px;
}

.pr-2 {
  padding-right: 20px;
}
